import React, { createRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button, ConfigProvider, DatePicker, Form, FormInstance, Input, InputNumber, message, Modal, Pagination, Radio, Select, Table, TablePaginationConfig, Tooltip, TreeSelect, Typography } from 'antd'
import TextArea from 'antd/lib/input/TextArea';
import moment from 'moment';
import 'moment/locale/zh-cn';
import locale from 'antd/es/locale/zh_CN';

import { axAssetConfig, axAssetConfigUpdate, axAssetPay, axAssetTypes, axAssetdisposal, axBatchdepre, axDeldepre, axDepreDate, axDepreStat, axFixDepre, axSubjectBindList, axSubjectList, cancelAsset, createAsset, createDaProof, dataDelAsset, getAssets, getCompanyAccoutList, getCompanyList, getCostTypeTree, getDaProofList, scrapAsset } from '../../utils/api'
import { uniAddRowSubmit, uniAxList, uniAxSearchList, uniCancel, uniCancelConfirm, uniChgRowSubmit, uniDatePikcerChange, uniExportExcel, uniFilterItem, uniFormProcess, uniInitFormdata, uniModifyAxList, uniOpAlarm, uniSelectRows, uniTableChange, uniToProof, uniValueToName } from '../../utils/uniFucs'
import { cancelStatus, csAssetBusinessIds, csAssetProperties, csAssetTypes, csPropTypes, csScrapNums, csYesNo, monthRages, payStatus, proofStatusList } from '../../utils/constant';
import formModal from '../../css/formModal.module.less'
import tablecss from '../../css/custable.module.less'
import TopSearch from '../../components/TopSearch';
import { AxDataform, ColumnAction, ColumnItem, FormItemObj } from '../../utils/interfaces';
import SuperTable from '../../components/SuperTable';
import FormDialog from '../../components/FormDialog';
import SummeryRow from '../../components/SummeryRow';

export default function FinanceAssets() {
const pathname = "/indexpage/financeassets"
const modalRef = createRef<FormInstance>()
const submodalRef = createRef<FormInstance>()
const searchRef = createRef<FormInstance>()
const initSearchParams = {orderBy:"bill_time", orderDir: "desc",filter_EQB_is_cancel:false,filter_EQL_is_scrap:0}
const initForminitial = {}
const initDistypes = [{id:'出售',name:'出售'},{id:'盘亏',name:'盘亏'},{id:'调出',name:'调出'},{id:'报废',name:'报废'},{id:'其他减少',name:'其他减少'},]
  /* hooks */
  let location = useLocation()
  const {state} = useLocation()
  const navigate = useNavigate()
  const formModalRef = createRef<FormInstance>()
  const [cancelModal, setCancelModal] = useState(false)
  const [proofModal, setProofModal] = useState(false)
  const [companyList, setCompanyList] = useState([])
  const [logList, setLogList] = useState([])
  const [rowList, setRowList] = useState([])
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [selectedRows, setSelectedRows] = useState([] as any)
  const [currentRow, setCurrentRow] = useState({} as any)
  let [searchParams, setSearchParams] = useState({...initSearchParams} as any)
  const [tableLoading, setTableLoading] = useState(false)
  const [pagination, setPagination] = useState<TablePaginationConfig>({current: 1, pageSize: 20})
  const [accountList, setAccountList] = useState([])
  const [cancelRemark, setCancelRemark] = useState('')
  const [loadFlag, setLoadFlag] = useState(false)
  const [modalLoading, setModalLoading] = useState(false)
  const [modalFeeTypeList, setModalFeeTypeList] = useState([])
  const [proofItemControl, setProofItemControl] = useState({maxYm:''})
  const [scrapModal,setScrapModal] = useState(false)
  const [modalFlag, setModalFlag] = useState('new')
const [showModal, setShowModal] = useState(false)
let [formInitials, setFormInitials] = useState({...initForminitial})
const [plusModalKeys, setPlusModalKeys] = useState({} as any)
let [assetTypeList,setAssetTypeList] = useState([])
let [showInfo,setShowInfo] = useState({payTime:true,payAccountId:true,taxPrice:true,depreciationToMonth:true})
let [exportLoading,setExportLoading] = useState(false)
let [statInfo,setStatinfo] = useState({originalPrice:0,hasdepreciationTotal:0,netPrice:0,taxPrice:0})
let [pagerowList,setPagerowList] = useState([])
let [modalrowList,setModalrowList] = useState([] as any)
let [companyItem, setCompanyItem] = useState({} as any)
let [subjectList, setSubjectList] = useState([])
let [freshFlag,setFreshFlag] = useState(false)
let [modaltableLoading,setModaltableLoading] = useState(false)
let [submodalFlag,setSubmodalFlag] = useState('')
let [showSubmodal,setShowSubmodal] = useState(false)
let [subrowList,setsubrowList] = useState([])
let [submodalLoading,setSubmodalLoading] = useState(false)

/* funcs */
//路由刷新请求
const routerFlash = () => {
  // uniAxList(dataRow())
  uniAxList(uniCompanyData)
  uniAxList(dataAssetTypes)
}
const searchConfirm = async (formValus:any) => {setPagination({...pagination,current:1});
  // searchParams = {...initSearchParams,...formValus}
  // setSelectedRows([])
  // setSelectedRowKeys([])
  pagination.current = 1
  searchParams = await uniFormProcess({...initSearchParams,...formValus},searchList)
  setSearchParams(searchParams)
  uniAxList(dataRow())
}
//modal点击确定
const modalConfirm = async (formValues:any) => {
  if (modalFlag==='fix') uniAxList(dataAssetConfigUpdate())
  else {
    let tempitems = modalFlag==='pay'? payItems:modalFlag==='proof'?proofItems:modalFlag==='disposal'?disposalItems:formItems
    let datafuc = modalFlag==='new'?dataNewrow:modalFlag==='proof'?dataProofrow:modalFlag==='disposal'?dataDisposalrow:dataPayrow
    let tempparms = await uniFormProcess(formValues,tempitems)
    if (modalFlag==='new' && tempparms.payTime && tempparms.billTime > tempparms.payTime) return message.error('付款日期不能小于账单日期')
    else if (modalFlag==='new' && tempparms.depreciationBeginMonth < tempparms.billTime) return message.error('折旧开始月份不能小于账单日期')
    if (modalFlag==='new') tempparms.code = 'GZ'+tempparms.code
    uniAxList(datafuc({...tempparms,...plusModalKeys}))
  }
}
//modal取消
const modalCancel = () => {
  setShowModal(()=>false)
  setPlusModalKeys({})
  setCurrentRow({})
  setModalrowList([])
  setFormInitials({...initForminitial})
}
//submodal点击确定
const submodalConfirm = async (formValues:any) => {
  let tempitems = batchdepreItems
  let datafuc = dataBatchdepre
  let tempparms = await uniFormProcess(formValues,tempitems)
  uniAxList(datafuc({...tempparms,...plusModalKeys,companyId:companyItem.id}))
}
//submodal取消
const submodalCancel = () => {
  setShowSubmodal(()=>false)
  setsubrowList([])
  setFormInitials({...initForminitial})
}
//批量作废点击确定
const onCancelConfirm = () => {
  setModalLoading(()=>true)
  let axList = selectedRowKeys.map((key:string) => ({id: key, cancelRemark}))
  cancelAsset(axList).then((res:any) => {
    if (res && res.message && res.message.toString().toLowerCase() === 'success') {
      message.success('已成功作废' + selectedRows.length + '条记录')
      uniAxList(dataRow())
    }
    setModalLoading(()=>false)
    setCancelModal(() => false)
  })
}
//生成折旧凭证
const onCreateProof = async () => {
  setProofModal(()=>true)
  let res = await getDaProofList({filter_EQS_fixed_assets_id: selectedRowKeys[0]})
  let proofrecords = res.data
  let maxYm = ''
  if (proofrecords && proofrecords.length>0) {
    proofrecords.forEach((item:any) => {
      if (item.ym > maxYm) maxYm = item.ym
    })
  }
  setProofItemControl(()=>({maxYm}))
}

/* elements */
//底部操作
const topmActions:ColumnAction[] = [
{label: '导出', type:'primary',loading:exportLoading,action: () => uniExportExcel({columns,rowList, title:'资产管理设置',fileName: '资产管理设置'})},
{label: '资产类型统计', type:'primary',action: () => {
  if (!companyItem.id) return message.error('请先选择公司')
  setModalFlag('deprestat')
  setShowModal(true)
  uniAxList(dataDepreStat())
}}
// {label: '导出', type:'primary',loading:exportLoading,action: () => uniExportExcel({columns,setExportLoading,axApi:getAssets, params:{...searchParams,page:null}, title:'资产管理设置',fileName: '资产管理设置'})}
]
//搜索行
const searchList:FormItemObj[] = [
{label: '公司', prop:'filter_EQS_company_id',type:'select',dataList:companyList,required:true,clearable:false,change:(value:any)=>{
  let finditem:any = companyList.find((item:any)=>item.id===value)
  setCompanyItem(finditem)
}},
{label: '名称', prop:'filter_LIKES_name'},
{label: '来源', prop:'filter_EQL_type',type:'select',dataList:csPropTypes},
{label: '类型', prop:'filter_EQL_assets_type_id',type:'select',dataList:assetTypeList},
{label: '处置状态', prop:'filter_EQB_is_disposal',type:'select',dataList:csYesNo},
{label: '付款状态', prop:'filter_EQB_is_pay',type:'select',dataList:payStatus},
// {label: '报废状态', prop:'filter_EQL_is_scrap',type:'select',dataList:csScrapNums},
]
// 列首行
const columns:ColumnItem[] = [
{title: '资产名称', key: 'name',type:'underline',unlineProps:{action:({row,value}:any)=>{
  // uniAxList(dataRowprooflogs(row.id))
  setModalFlag('detail')
  setCurrentRow(row)
  setFormInitials(uniInitFormdata(row,formItems))
  setShowModal(true)
}}},
{title: '卡片编码',key: 'code',maxWidth:120},
{title: '公司',key: 'companyName'},
{title: '来源',key: 'type',custval:({row,value}:any)=>uniValueToName({list:csPropTypes,value})},
{title: '类型',key: 'assetsTypeId',custval:({row,value}:any)=>uniValueToName({list:assetTypeList,value})},
{title: '数量',key: 'number'},
// {title: '使用数量',key: 'useNumber'},
{title: '原值',key: 'originalPrice',type:'price'},
{title: '残值率%',key: 'scrapValueRate'},
{title: '累计折旧额',width:100,key: 'hasdepreciationTotal',custval:({row,value}:any)=>(row.depreciationMonth * row.depreciationPrice / 100).toFixed(2)},
{title: '资产净值',key: 'netPrice',custval:({row,value}:any)=>((row.originalPrice - row.depreciationMonth * row.depreciationPrice) / 100).toFixed(2)},
// {title: '折旧总值',key: 'depreciationTotalPrice',type:'price'},
{title: '税额',key: 'taxPrice',type:'price'},
{title: '资产位置',key: 'assetsPlace'},
// {title: '备注',key: 'remark'},
// {title: '作废状态',key: 'isCancel',type:'tag',tagProps:{values:[true,false]},custval:({row,value}:any)=>uniValueToName({list:cancelStatus,value})},
// {title: '折旧开始月',width:100,key: 'depreciationBeginMonth'},
// {title: '折旧结束月',width:100,key: 'depreciationEndMonth'},
// {title: '折旧总月份',width:100,key: 'depreciationTotalMonth'},
// {title: '已折旧月数',width:100,key: 'depreciationMonth'},
// {title: '最近折旧月',width:100,key: 'depreciationToMonth'},
// {title: '每月折旧',key: 'depreciationPrice',type:'price'},
// {title: '是否付款',key: 'isPay',custval:({row,value}:any) => value===0?'无需付款':value===1?'已付款':value},
{title: '账单日期', key: 'billTime',custval:({row,value}:any) => value ? value.split(' ')[0] : ''},
{title: '付款日期', key: 'payTime',custval:({row,value}:any) => value ? value.split(' ')[0] : ''},
// {title: '付款账户',key: 'payAccount'},
{title: '是否处置',key: 'isDisposal',type:'tag',width:80,custval:({row,value}:any)=>uniValueToName({list:csYesNo,value})},
{title: '处置时间',key: 'disposalTime'},
{title: '处置类型',key: 'disposalType'},
{title: '处置备注',key: 'disposalRemark'},
{title: '付款状态',key: 'isPay',type:'tag',custval:({row,value}:any)=>uniValueToName({list:payStatus,value})},
{title: '凭证状态',key: 'proofStatus',custval:({row,value}:any)=>uniValueToName({list:proofStatusList,value})},
{title: '凭证号',key: 'accountProof',width:120,ellipsis:false,render: (value:any,row:any) => {
  return <div style={{maxWidth:170}}>
    {value.map((item:any) => <span key={item.accountProofId}><Typography.Link onClick={()=>uniToProof(row,item.accountProofId,navigate)}>{item.accountProofNo}</Typography.Link> </span>)}
  </div>
}},
{title: '操作',key:'operation',type: 'operation',width:120,opProps:{opList:[
  {name:'付款',disabled:({row}:any)=>row.isPay===1,action:({row}:any) => {
    uniAxList(dataFirmacountList(row.companyId))
    setModalFlag('pay')
    setCurrentRow(row)
    setFormInitials(uniInitFormdata(row,formItems))
    setShowModal(true)
  }},
  // {name:'生成折旧凭证',action:({row}:any) => {
  //   setModalFlag('proof')
  //   setCurrentRow(row)
  //   setShowModal(true)
  // }},
  {name:'处置',disabled:({row}:any)=>row.isDisposal,action:({row}:any) =>{
    // uniOpAlarm(()=>uniAxList(dataDelrow([{id:row.id}])))
    setModalFlag('disposal')
    setCurrentRow(row)
    setFormInitials({})
    setShowModal(true)
  }},
  {name:'删除',color:'red',disabled:({row}:any)=>row.depreciationMonth,action:({row}:any) =>uniOpAlarm(()=>uniAxList(dataDelrow([row.id])))},
]}}
]
//底部操作
const bottomActions:ColumnAction[] = [
{label: '新增资产', type:'primary', action: () => {
  setFormInitials({scrapValueRate:5})
  setModalFlag('new');
  setShowModal(true)}
},
{label: '固定资产配置', type:'primary', action: () => {
  if (!companyItem.id) return message.error('请先选择公司')
  setModalFlag('fix')
  setShowModal(true)
  uniAxList(dataAssetConfig(companyItem.id))
  uniAxList(dataSubjectList(companyItem.id))
}},
{label: '资产折旧', type:'primary', action: () => {
  if (!companyItem.id) return message.error('请先选择公司')
  setModalFlag('depre')
  setShowModal(true)
  uniAxList(dataDepreList(companyItem.id))
}},
{label: 'bottomslot', render:<>
  <span style={{color: '#1890ff'}}>原值: </span>&nbsp;<span>{(statInfo.originalPrice/100).toFixed(2)}</span>&nbsp;&nbsp;
  <span style={{color: '#1890ff'}}>累计折旧额: </span>&nbsp;<span>{(statInfo.hasdepreciationTotal/100).toFixed(2)}</span>&nbsp;&nbsp;
  <span style={{color: '#1890ff'}}>资产净值: </span>&nbsp;<span>{(statInfo.netPrice/100).toFixed(2)}</span>&nbsp;&nbsp;
  <span style={{color: '#1890ff'}}>税额: </span>&nbsp;<span>{(statInfo.taxPrice/100).toFixed(2)}</span>
</>},
]
//添加和修改中的model项
const formItems:FormItemObj[] = [
{label: '所属公司',prop: 'companyId',type:'select',selectProps:{requireName:'companyName'},dataList:companyList,change:(value:any)=>{
  let finditem:any = companyList.find((item:any)=>item.id===value)
  if (finditem && finditem.endYm && finditem.startYm !== finditem.endYm) {
    modalRef.current?.setFieldsValue({depreciationToMonth: moment(finditem.endYm).subtract(1,'month').format('YYYY-MM')})
  }
  uniAxList(dataFirmacountList(value))
}},
{label: '卡片编码',prop: 'code',show:modalFlag==='detail',disabled:modalFlag==='detail'},
{label: '卡片编码',prop: 'code',show:modalFlag==='new',type:'slot',slotProps:<Input.Search addonBefore="GZ" size='small' type={'number'}
  placeholder="输入编码"
  enterButton="自动生成"
  style={{width: '100%'}}
  onSearch={async (value) => {
    let companyid = modalRef.current?.getFieldValue('companyId')
    if (!companyid) return message.error('请先选择公司')
    let res = await getAssets({orderBy:"code", orderDir: "desc",filter_LIKES_code:'GZ',filter_EQS_company_id:companyid,page:1, size:1})
    let maxcode = 0
    if (res.data.records.length > 0) maxcode = Number(res.data.records[0].code.slice(2))
    let newcode = (maxcode+1).toString().padStart(7, '0')
    modalRef.current?.setFieldsValue({code: newcode})
  }}
  onBlur={(e) => {
    modalRef.current?.setFieldsValue({code: e.target.value.toString().padStart(7, '0')})
  }}/>
},
{label: '账单日期',prop: 'billTime',type:'datePicker',clearable:false,datepickerProps:{returnType:'string'},change:(date:any,datestring:any)=>{
  let totalmonth = modalRef.current?.getFieldValue('depreciationTotalMonth')
  modalRef.current?.setFieldsValue({depreciationBeginMonth:moment(datestring).add(1,'month').format('YYYY-MM'),depreciationEndMonth:totalmonth?moment(datestring).add(totalmonth+1,'month').format('YYYY-MM'):null})
}},
{label: '资产来源',prop: 'type',type:'select',dataList:csPropTypes,clearable:false,change:(value:any)=>{
  if (value === 1) setShowInfo({...showInfo,payTime:false,payAccountId:false,taxPrice:false,depreciationToMonth:true})
  else setShowInfo({...showInfo,payTime:true,payAccountId:true,taxPrice:true,depreciationToMonth:false})
}},
{label: '资产名称',prop: 'name'},
{label: '资产数量',prop: 'number',type:'number'},
{label: '资产类型',prop: 'assetsTypeId',type:'select',clearable:false,dataList:assetTypeList,change:(value:any)=>{
  let finditem:any = assetTypeList.find((item:any)=>item.id===value)
  if (finditem && finditem.month) {
    let begindatestr = modalRef.current?.getFieldValue('depreciationBeginMonth')
    modalRef.current?.setFieldsValue({depreciationTotalMonth:finditem.month,depreciationEndMonth:begindatestr?moment(begindatestr).add(finditem.month,'month').format('YYYY-MM'):null})
    let originalPrice = modalRef.current?.getFieldValue('originalPrice')
    let scrapValueRate = formModalRef.current?.getFieldValue('scrapValueRate')
    if (originalPrice && scrapValueRate) modalRef.current?.setFieldsValue({depreciationPrice:Number(((originalPrice-originalPrice*scrapValueRate/100)/finditem.month).toFixed(2))})
    else if (originalPrice) modalRef.current?.setFieldsValue({depreciationPrice: Math.floor(originalPrice/finditem.month * 100) / 100})
  }
}},
{label: '资产原值',prop: 'originalPrice', type:'price',change:(value:number)=>{
  let tempvalue = value || 0
  let scrapValueRate = modalRef.current?.getFieldValue('scrapValueRate') || 0
  let depreciationTotalMonth = modalRef.current?.getFieldValue('depreciationTotalMonth')
  modalRef.current?.setFieldsValue({depreciationTotalPrice:Number((tempvalue-tempvalue*scrapValueRate/100).toFixed(2))})
  if (scrapValueRate && depreciationTotalMonth) modalRef.current?.setFieldsValue({depreciationPrice:Number(((tempvalue-tempvalue*scrapValueRate/100)/depreciationTotalMonth).toFixed(2))})
  else if (depreciationTotalMonth) modalRef.current?.setFieldsValue({depreciationPrice: Number((tempvalue/depreciationTotalMonth).toFixed(2))})
}},
{label: '残值率(%)',prop: 'scrapValueRate',required:false,type:'number',change:(value:number)=>{
  let tempvalue = value || 0
  let originalPrice = modalRef.current?.getFieldValue('originalPrice') || 0
  let depreciationTotalMonth = modalRef.current?.getFieldValue('depreciationTotalMonth')
  modalRef.current?.setFieldsValue({depreciationTotalPrice:Number((originalPrice-originalPrice*tempvalue/100).toFixed(2))})
  if (originalPrice && depreciationTotalMonth) modalRef.current?.setFieldsValue({depreciationPrice: Number(((originalPrice-originalPrice*tempvalue/100)/depreciationTotalMonth).toFixed(2))})
  // if (originalPrice && depreciationTotalMonth) modalRef.current?.setFieldsValue({depreciationPrice: Math.floor((originalPrice-originalPrice*tempvalue/100)/depreciationTotalMonth * 100) / 100})
}},
{label: '折旧总值',prop: 'depreciationTotalPrice', type:'price',disabled:true},
{label: '税额',prop: 'taxPrice',show:showInfo.taxPrice,type:'price',required:false},
// {label: '折旧开始月份',prop: 'depreciationBeginMonth',type:'datePicker',disabled:true,datepickerProps:{returnType:'string',type:'month',disabledDate:(current:any)=>false}},
{label: '折旧开始月份',prop: 'depreciationBeginMonth',type:'input',disabled:true},
{label: '折旧总月份',prop: 'depreciationTotalMonth',disabled:true,type:'number'},
// {label: '折旧结束月份',prop: 'depreciationEndMonth',type:'datePicker',disabled:true,datepickerProps:{returnType:'string',type:'month'}},
{label: '折旧结束月份',prop: 'depreciationEndMonth',type:'input',disabled:true},
{label: '最近折旧月份',prop: 'depreciationToMonth',show:showInfo.depreciationToMonth,type:'input',disabled:true,required:false},
{label: '已折旧月数',prop: 'depreciationMonth',show:modalFlag==='detail',type:'input',disabled:true,required:false},
{label: '每月折旧金额',prop: 'depreciationPrice',type:'price',disabled:true},
{label: '付款日期',prop: 'payTime',show:showInfo.payTime,required:false,type:'datePicker',datepickerProps:{returnType:'string'}},
{label: '付款账户',prop: 'payAccountId',show:showInfo.payAccountId,required:false,type:'select',selectProps:{requireName:'payAccount',childrenKeys:['name','cardNo']},dataList:accountList},
{label: '资产位置',prop: 'assetsPlace',span:12,required:false},
{label: '备注',prop: 'remark',span:12,required:false},
]
//付款model项
const payItems:FormItemObj[] = [
{label: '付款日期',prop: 'payTime',required:false,type:'datePicker',datepickerProps:{returnType:'string',disabledDate:(current)=>current<moment(currentRow.billTime) || current>moment()}},
{label: '付款账户',prop: 'payAccountId',required:false,type:'select',selectProps:{requireName:'payAccount',childrenKeys:['name','cardNo']},dataList:accountList}
]
//生成记账凭证的model
const proofItems:FormItemObj[] = [
{label: '月份范围',prop:'months',type:'dateRange',daterangeProps:{returnType:'string',type:'month',rangeKeys:['beginYm','endYm'],disabledDate:(current)=>current<moment(currentRow.depreciationBeginMonth) || current > moment()}}
]
//一键折旧model
const batchdepreItems:FormItemObj[] = [
{label: '月份',prop:'ym',type:'datePicker',datepickerProps:{returnType:'string',type:'month',disabledDate:(current)=>{
  if (companyItem.initialize !== 1 || !companyItem.startYm) return true
  else if (modalrowList.length > 0) return current<moment(modalrowList[0].ym).add(1,'month') || current>moment()
  else return current<moment(companyItem.startYm) || current>moment()
}}}
]
//处置model
const disposalItems:FormItemObj[] = [
{label: '处置类型',prop: 'disposalType',type:'select',dataList:initDistypes},
{label: '处置时间',prop: 'disposalTime',type:'datePicker',datepickerProps:{returnType:'string'}},
{label: '备注',prop: 'disposalRemark',required:false}
]
//凭证记录中的列项
const logColumns:ColumnItem[] = [
{title: '凭证月份',key: 'ym'},
{title: '凭证状态',key: 'proofStatus',custval:({row,value}:any)=>uniValueToName({list:proofStatusList,value})},
{title: '凭证号',key: 'accountProof',ellipsis:false,render: (value:any,row:any) => {
  return <div style={{maxWidth:170}}>
    {value.map((item:any) => <span key={item.accountProofId}><Typography.Link onClick={()=>{
      setShowModal(false)
      uniToProof(row,item.accountProofId,navigate)
    }}>{item.accountProofNo}</Typography.Link> </span>)}
  </div>
}},
{title: '是否作废',key: 'isCancel',custval:({row,value}:any)=>uniValueToName({list:cancelStatus,value})},
{title: '更新时间', key: 'updateDate'},
]
//固定资产配置中的列项
const fixColumns:ColumnItem[] = [
{title: '配置名',key: 'configName'},
{title: '会计科目',key: 'subjectId',type:'select',clearable:false,dataList:()=>subjectList,selectProps:{propName:'title'},change: async (value:any,row:any)=>{
  row.subjectId = value
  row.subjectAccountingDetailId = null
  let resdata = await uniAxList(dataSubjectBindList(companyItem.id,value))
  row.compundList = resdata
  setModalrowList(()=>[...modalrowList])
}},
{title: '辅助详情组合',key: 'subjectAccountingDetailId',type:'select',disabled:({row}:any)=>!row.compundList || row.compundList.length<=0,dataList:(row:any)=>row.compundList || [],selectProps:{propName:'noName'},change:(value:any,row:any)=>{
  row.subjectAccountingDetailId = value
  setModalrowList(()=>[...modalrowList])
}},
]
//固定资产统计的列项
const statColumns:ColumnItem[] = [
{title: '资产类型',key: 'assetsTypeName'},
{title: '折旧金额',key: 'depreciationPrice',type:'price'},
{title: '原值',key: 'originalPrice',type:'price'}
]
//资产折旧列项
const depreColumns:ColumnItem[] = [
{title: '凭证号',key: 'proofNo',type:'underline',unlineProps:{color: '#1890ff',action:({row,value}:any)=>{
  setShowModal(false)
  uniToProof(row,row.accountProof, navigate)
}}},
{title: '折旧月',key: 'ym'},
{title: '操作',key:'operation',type: 'operation',width:120,opProps:{opList:[
  {name:'详情',action:({row}:any) => {
    setSubmodalFlag('depredetail')
    setShowSubmodal(true)
    setsubrowList(row.depreciationData)
  }},
  {name:'删除',color:'red',disabled:({row,index}:any)=>index !== modalrowList.length-1,action:({row}:any) =>uniOpAlarm(()=>uniAxList(dataDeldepre(row.ym)))},
]}}
]
//资产折旧详情列项
const depredetailColumns:ColumnItem[] = [
{title: '资产名',key: 'assetsTypeName'},
{title: '金额',key: 'price',type:'price'}
]
//table统计列
const staticColumns = [
{mapIndex:5, mapKeys:['originalPrice'], sum:0, type:'price'},
{mapIndex:7, mapKeys:['hasdepreciationTotal'], sum:0, type:'price'},
{mapIndex:8, mapKeys:['netPrice'], sum:0, type:'price'},
{mapIndex:9, mapKeys:['taxPrice'], sum:0, type:'price'}
]

/* 接口参数 */
const dataRow =():AxDataform => ({
  params: {...searchParams},
  axApi: getAssets,
  setList: setRowList,
  setTableLoading,
  awaitFunc: (predata:any)=>{
    let temptotal = {'originalPrice':0,'hasdepreciationTotal':0,'netPrice':0,'taxPrice':0}
    predata.forEach((item:any)=>{
      let hasdepreciationTotal = item.depreciationMonth * item.depreciationPrice
      let netPrice = item.originalPrice - item.depreciationMonth * item.depreciationPrice
      temptotal.originalPrice += item.originalPrice
      temptotal.hasdepreciationTotal += hasdepreciationTotal
      temptotal.netPrice += netPrice
      temptotal.taxPrice += item.taxPrice
    })
    setPagination({...pagination,total:predata.length})
    setStatinfo(temptotal)
    setPagerowList(predata.slice(0,pagination.pageSize))
  }
})
//新建行
const dataNewrow = (params:any):AxDataform => ({
  params,
  axApi: createAsset,
  setModalLoading,
  setShowModal,
  awaitFunc: (predata:any) => uniAxList(dataRow())
})
//确认付款行
const dataPayrow = (params:any):AxDataform => ({
  params: {...params, id:currentRow.id},
  axApi:  axAssetPay,
  setModalLoading,
  setShowModal,
  awaitFunc: (predata:any) => uniAxList(dataRow())
})
//批量折旧-一键折旧
const dataBatchdepre = (params:any):AxDataform => ({
  params,
  axApi: axBatchdepre,
  setModalLoading:setSubmodalLoading,
  setShowModal: setShowSubmodal,
  awaitFunc: (predata:any) => uniAxList(dataDepreList(companyItem.id))
})
//资产处置
const dataDisposalrow = (params:any):AxDataform => ({
  params: {...params, id:currentRow.id},
  axApi: axAssetdisposal,
  setModalLoading,
  setShowModal,
  awaitFunc: (predata:any) => uniAxList(dataRow())
})
//折旧数据
const dataDepreList = (companyId:any):AxDataform => ({
  params: {filter_EQL_company_id:companyId},
  axApi:  axFixDepre,
  setList:setModalrowList,
  setTableLoading: setModaltableLoading
})
//作废删除行
const dataDeldepre = (ym:any):AxDataform => ({
  params: {companyId:companyItem.id,ym},
  axApi: axDeldepre,
  awaitFunc: (predata:any) => uniAxList(dataDepreList(companyItem.id))
})
//生成折旧凭证
const dataProofrow = (params:any):AxDataform => ({
  params: {...params, fixedAssetsId:currentRow.id},
  axApi:  createDaProof,
  setModalLoading,
  setShowModal,
  awaitFunc: (predata:any) => uniAxList(dataRow())
})
//查看折旧凭证
const dataRowprooflogs = (rowid:any):AxDataform => ({
  params: {filter_EQS_fixed_assets_id: rowid},
  axApi: getDaProofList,
  setTableLoading:setModaltableLoading,
  setList: setLogList
})
//作废删除行
const dataDelrow = (rowids:any):AxDataform => ({
  params: rowids,
  axApi: dataDelAsset,
  awaitFunc: (predata:any) => uniAxList(dataRow())
})
//固定资产类型
const dataAssetTypes:AxDataform = {
  axApi: axAssetTypes,
  setList: setAssetTypeList,
}
//固定资产配置列表
const dataAssetConfig = (companyId:any):AxDataform => ({
  params:{companyId},
  axApi: axAssetConfig,
  setTableLoading: setModaltableLoading,
  finishLoading: false,
  awaitFunc: async (predata:any) => {
    let temparr:any = []
    for (let i=0;i<predata.config.length;i++) {
      let item = predata.config[i]
      let tempobj:any = {configId:item.id,configName:item.name,companyId:companyItem.id}
      let detailitem = predata.detail.find((detailitem:any)=>detailitem.configId===item.id)
      if (detailitem) {
        tempobj.subjectId = detailitem.subjectId
        tempobj.subjectAccountingDetailId = detailitem.subjectAccountingDetailId
        let resdata = await uniAxList(dataSubjectBindList(companyItem.id,detailitem.subjectId))
        tempobj.compundList = resdata
      }
      temparr.push(tempobj)
    }
    setModalrowList(temparr)
    setModaltableLoading(false)
  }
})
//更新固定资产配置列表
const dataAssetConfigUpdate = ():AxDataform => ({
  params: modalrowList,
  axApi:axAssetConfigUpdate,
  setModalLoading,
  setShowModal,
  successTip: true
})
//固定资产折旧统计
const dataDepreStat = ():AxDataform => ({
  params: {companyId: companyItem.id},
  axApi:axDepreStat,
  setList: setModalrowList,
  setTableLoading: setModaltableLoading
})
//获取科目详情组合
const dataSubjectBindList = (companyId:any,subjectId:any):AxDataform => {
  return {
    params:{companyId,subjectId},
    axApi: axSubjectBindList
  }
}
//公司账户
const dataFirmacountList = (companyId:any):AxDataform => ({
  params:{companyId},
  axApi: getCompanyAccoutList,
  setList: setAccountList
})
//公司会计科目列表
const dataSubjectList = (companyId:any):AxDataform => ({
  params: {page:1, size:999,companyId,showProof:true},
  axApi: axSubjectList,
  itemModify: {modifyFunc:({item}:any)=>item.title=item.subjectNo+' '+item.name},
  setList: setSubjectList
})
//折旧月份
const datDepreDate = ():AxDataform => ({
  params: {companyId:companyItem.id},
  axApi: axDepreDate
})
const uniCompanyData:any = {
  axApi: getCompanyList,
  setList: setCompanyList,
}
const uniModalFeeTypeData:any = {
  params:{filter_EQL_business_id:96,size:9999},
  axApi: getCostTypeTree,
  modifyFunc: uniModifyAxList,
  modifyData: {
    titleList:['title', 'name'],
    custFunc:(item:any) => {
      if (item.businessId !== 96) item.disabled = true
    }
  },
  setList: setModalFeeTypeList,
}
/* useEffect */
//监听路由变化
React.useEffect(() => {
  if (loadFlag && location.pathname===pathname) routerFlash()
}, [location])
/* mounted */
React.useEffect(() => {
  // uniAxList(dataRow())
  uniAxList(uniCompanyData)
  uniAxList(dataAssetTypes)
  setLoadFlag(()=>true)
}, [])

return (<>
  <TopSearch
    ref={searchRef}
    topActions={topmActions}
    initialValues={initSearchParams}
    searchList={searchList}
    searchConfirm={searchConfirm}
  />
  <SuperTable
    columns={columns}
    rowList={pagerowList}
    bottomActions={bottomActions}
    tableLoading={tableLoading}
    pagination={pagination}
    dataRow={dataRow}
    inPagination={(page:any, pageSize:any)=>{
      setPagerowList(rowList.slice(pageSize*(page-1),pageSize*page))
    }}
  />
  <FormDialog
    ref={modalRef}
    type={['detail','fix','depre'].includes(modalFlag)?'drawer':'modal'}
    title={modalFlag==='new'?'新增资产':modalFlag==='deprestat'?'资产类型统计':modalFlag==='proof'?'生成折旧凭证':modalFlag==='pay'?'确认付款':modalFlag==='detail'?'详情':modalFlag==='fix'?`固定资产配置-${companyItem.name}`:modalFlag==='depre'?`资产折旧-${companyItem.name}`:modalFlag==='disposal'?'处置':''}
    show={showModal}
    formItems={modalFlag==='proof'?proofItems:modalFlag==='pay'?payItems: ['fix','depre','deprestat'].includes(modalFlag)?[]:modalFlag==='disposal'?disposalItems:formItems}
    modalLoading={modalLoading}
    formInitials={formInitials}
    cancel={modalCancel}
    confirm={modalConfirm}
    showCustom={['fix','depre','deprestat'].includes(modalFlag)}
    hasConfirm={['detail','depre','deprestat'].includes(modalFlag)?false:true}
  >
    <></>
    {
      modalFlag==='depre'
      ?<>
        <Button size='small' type='primary' style={{width:80}} onClick={async ()=>{
          if (!companyItem.endYm) return message.error('账套未启用')
          let resdate = await uniAxList(datDepreDate())
          uniOpAlarm(()=>uniAxList(dataBatchdepre({ym:resdate,companyId:companyItem.id})),`折旧月份 ${resdate}, 是否确认该操作`)
        }} >一键折旧</Button>
        <SuperTable
          columns={depreColumns}
          rowList={modalrowList}
          tableLoading={modaltableLoading}
        />
      </>
      :<SuperTable
        rowKey={modalFlag==='fix'?'configId':'assetsTypeName'}
        columns={modalFlag==='fix'?fixColumns:statColumns}
        rowList={modalrowList}
        tableLoading={modaltableLoading}
      />
    }
  </FormDialog>
  <FormDialog
    ref={submodalRef}
    title={submodalFlag==='batchdepre'?'一键折旧':'折旧详情'}
    show={showSubmodal}
    formItems={submodalFlag==='batchdepre'?batchdepreItems:[]}
    modalLoading={submodalLoading}
    formInitials={{}}
    cancel={submodalCancel}
    confirm={submodalConfirm}
    showCustom={['depredetail'].includes(submodalFlag)}
    hasConfirm={modalFlag==='depredetail'?false:true}
  >
    <></>
    <SuperTable
      rowKey='assetsTypeId'
      columns={depredetailColumns}
      rowList={subrowList}
    />
  </FormDialog>
  {/* <Modal
    visible={scrapModal}
    title="确定进行批量报废吗？"
    destroyOnClose
    onCancel={()=>setScrapModal(()=>false)}
    onOk={() => uniCancelConfirm({selectedRows,cancelApi:scrapAsset,axData:dataRow(),setCancelModal:setScrapModal,setModalLoading})}
    footer={[<Button key="back" onClick={()=>setScrapModal(()=>false)}>取消</Button>, <Button loading={modalLoading} key="submit" type="primary" onClick={() => uniCancelConfirm({selectedRows,cancelApi:scrapAsset,axData:dataRow(),setScrapModal,setModalLoading})}>确定</Button>]}
  >
  </Modal> */}
</>)
}
