import React, { createRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button, ConfigProvider, DatePicker, FormInstance, InputNumber, message, Modal, Popconfirm, Select, Table, TablePaginationConfig, TreeSelect, Typography } from 'antd'
import moment from 'moment';
import 'moment/locale/zh-cn';
import locale from 'antd/es/locale/zh_CN';

import { axCompanyFlow, axCreateCashflowSubject, axcreateFlowAdjust, axDeleteCashflowSubject, axFlowAdjustsum, axupdateFlowAdjust, axYearFlowAdjustsum, axSubjectList, getCompanyList, xjllAnalyse } from '../../utils/api'
import { uniAxList, uniAxSearchList, uniCurrentTime, uniExportExcel, uniFilterItem, uniFormProcess, uniModifyAxList, uniPrintTable, uniSelectRows, uniValueToName } from '../../utils/uniFucs'
import { calculateTypes, calculateTypeTypes, csFlowTypes, monthRages } from '../../utils/constant';
import modalcss from '../../css/formModal.module.less'
import tablecss from '../../css/custable.module.less'
import { AxDataform, ColumnAction, FormItemObj } from '../../utils/interfaces';
import TopSearch from '../../components/TopSearch';

export default function GpCashflow() {
  const pathname = "/indexpage/gpcashflow"
  const initSubjectModalControl = {selectSubjectId:null,selectSubjectName:null,selectCalculate:null,selectCalculateType:null,subjectList:[],selectSubjectNo:null}
  const initMainControl = {selectYear:uniCurrentTime({selectYear:moment(),beginYm:moment(),endYm:moment(),isBalance:false,selectType:1,selectedPicer:uniCurrentTime({day:false})})}
  const searchRef = createRef<FormInstance>()
  const tableref = createRef<any>()

  /* hooks */
  let location = useLocation()
  const navigate = useNavigate()
  const formModalRef = createRef<FormInstance>()
  const [companyList, setCompanyList] = useState([])
  const [rowList, setRowList] = useState([] as any)
  const [currentRow, setCurrentRow] = useState({} as any)
  let [searchParams, setSearchParams] = useState({} as any)
  // let [searchParams, setSearchParams] = useState({type:1,number:Number(uniCurrentTime({year:false,day:false})),year:uniCurrentTime({month:false,day:false}),ym:uniCurrentTime({day:false})} as any)
  const [tempSearchParams, setTempSearchParams] = useState({type:1,number:Number(uniCurrentTime({year:false,day:false})),year:uniCurrentTime({month:false,day:false}),ym:uniCurrentTime({day:false})} as any)
  const [staffList, setStaffList] = useState([])
  const [reinburseTypeList, setReinburseTypeList] = useState([])
  const [tableLoading, setTableLoading] = useState(false)
  const [loadFlag, setLoadFlag] = useState(false)
  const [subjectModal,setSubjectModal] = useState(false)
  const [subjectList,setSubjectList] = useState([])
  const [modalLoading, setModalLoading] = useState(false)
  const [subjectModalControl, setSubjectModalControl] = useState({selectSubjectId:null,selectSubjectName:null,selectSubjectNo:null,selectCalculate:null,selectCalculateType:null,subjectList:[]})
  const [subjectTableLoading, setSubjectTableLoading] = useState(false)
  const [mainControl, setMainControl] = useState({selectYear:moment(),beginYm:moment(),endYm:moment(),isBalance:false,selectType:1,selectedPicker:uniCurrentTime({day:false})} as any)
  const [adjustModal,setAdjustModal] = useState(false)
  const [companyFlowList,setCompanyFlowList] = useState([] as any)
  const [adjustTableLoading,setAdjustTableLoading] = useState(false)
  const [companyFlowListInit,setCompanyFlowListInit] = useState([])
  const [searchYmRange,setSearchYmRange] = useState([] as any)

  /* funcs */
  //路由刷新请求
  const routerFlash = () => {
    // uniAxList(dataFlowAdjustsum)
    uniAxList(uniCompanyData)
    uniAxList(uniSubjectData)
  }
  const searchConfirm = async (formValus:any) => {
    searchParams = {...await uniFormProcess(formValus,searchList)}
    setSearchParams(searchParams)
    uniAxList(dataFlowAdjustsum)
  }

/* elements */
//搜索行
const searchList:FormItemObj[] = [
  {label: '公司', prop:'companyId',type:'select',dataList:companyList,required:true,change:(value:any)=>{
    let finditem:any = companyList.find((item:any)=>item.id===value)
    if (finditem.initialize===1) setSearchYmRange([finditem.startYm,finditem.endYm])
    else setSearchYmRange([])
  }},
  {label: '月份', prop:'ym',type:'datePicker',required:true,datepickerProps:{type:'month',returnType:'string',disabledDate:(current)=>{
    if (searchYmRange.length<2) return true
    else return current<moment(searchYmRange[0]).startOf('month') || current>moment(searchYmRange[1]).endOf('month')
  }}},
  ]
  //顶部操作
const topActions:ColumnAction[] = [
{label: '导出', type:'primary',action: () => {
  // var printContent = document.getElementById('tabletest')?.innerHTML;
  // var originalContent = document.body.innerHTML;
  // document.body.innerHTML = printContent as any;
  // window.print();
  // document.body.innerHTML = originalContent;
  uniExportExcel({columns,rowList,title:'现金流量表',fileName: '现金流量表'+uniCurrentTime({}),titlecounts:3,custtitles:{
    A2:{t:'s',v:searchParams.ym,s:{font:{bold:true},alignment:{horizontal:'center',vertical:'center'}}},
    E2:{t:'s',v:'会企03表',s:{font:{bold:true},alignment:{horizontal:'right',vertical:'center'}}},
    A3:{t:'s',v:`编制单位: ${(companyList.find((item:any)=>item.id===searchParams.companyId) as any).name}`,s:{font:{bold:true},alignment:{horizontal:'left',vertical:'center'}}},
    E3:{t:'s',v:'单位: 元',s:{font:{bold:true},alignment:{horizontal:'right',vertical:'center'}}},
  },titleMergeList:[{s:{r:1,c:0},e:{r:1,c:3}},{s:{r:2,c:0},e:{r:2,c:3}}],columnws:[{wch:70},{wch:15},{wch:15},{wch:15},{wch:15}]})
}},
{label: '是否平衡', type:'primary',render: mainControl.isBalance
  ? <img src={require('../../materials/img/pingheng.png')} alt="" /> 
  : <img src={require('../../materials/img/bupingheng.png')} alt="" />
}
]
  //列首行
  const columns:any = [
    {
      title: '项目',
      dataIndex: 'name',
      key: 'name',
      width: 600,
      render: (value:any,record:any) => record.id >= 0 
      ? <Typography.Link onClick={()=>{
        setSubjectModalControl(()=>({...initSubjectModalControl,subjectList:record.detail}))
        setCurrentRow(()=>record)
        setSubjectModal(()=>true)
      }}>{value}</Typography.Link>
      : value
    },
    {
      title: '行次',
      dataIndex: 'count',
      key: 'count',
    },
    // {
    //   title: '运算规则',
    //   dataIndex: 'calculate',
    //   key: 'calculate',
    //   custval:({row,value}:any)=>{
    //     let tempItem:any = uniFilterItem(calculateTypes,'value',value)
    //     return tempItem ? tempItem.name : ''
    //   },
    //   render:(value:any) => {
    //     let tempItem:any = uniFilterItem(calculateTypes,'value',value)
    //     return tempItem ? tempItem.name : ''
    //   }
    // },
    {
      title: '本月金额',
      dataIndex: 'monthPrice',
      key: 'monthPrice',
      custval:({row,value}:any)=>value ? Number((value / 100).toFixed(2)) : value,
      render: (value:any) => value ? Number((value / 100).toFixed(2)) : value
    },
    {
      title: '本年累计金额',
      dataIndex: 'yearPrice',
      key: 'yearPrice',
      custval:({row,value}:any)=>value ? Number((value / 100).toFixed(2)) : value,
      render: (value:any) => value ? Number((value / 100).toFixed(2)) : value
    },
  ]
  //modal中table列项
  const columnsInSubjectModal:any = [
    {
      title: '科目',
      dataIndex: 'subjectName',
      key: 'subjectName',
    },
    {
      title: '运算符号',
      dataIndex: 'calculate',
      key: 'calculate',
      render:(value:any) => {
        let tempItem:any = uniFilterItem(calculateTypes,'value',value)
        return tempItem ? tempItem.name : ''
      }
    },
    {
      title: '取数规则',
      dataIndex: 'calculateType',
      key: 'calculateType',
      render:(value:any) => uniValueToName({list:calculateTypeTypes,value,propId:'value'})
    },
    {
      title: '本期金额',
      dataIndex: 'monthPrice',
      key: 'monthPrice',
      render: (value:any) => value && !isNaN(value) ? (value/100).toFixed(2) : 0
    },
    {
      title: '本年金额',
      dataIndex: 'yearPrice',
      key: 'yearPrice',
      render: (value:any) => value && !isNaN(value) ? (value/100).toFixed(2) : 0
    },
    {
      title: '操作',
      dataIndex: 'operation',
      fixed: 'right',
      width: 120,
      render: (_: any, record: any) => 
            <Popconfirm title={"确定删除该记录吗?"} okText="确定" cancelText="取消" onConfirm={() => uniAxList({...dataDeleteCashflowSubject,params:{id:record.cashFlowSubjectId}})}>
              <a href="#">删除</a>
            </Popconfirm>

    }
  ]
  //修改余额table列项
  const adjustColumns = [
    {
      title: '公司',
      dataIndex: 'companyName',
      key: 'companyName',
    },
    {
      title: '调整金额',
      dataIndex: 'balance',
      key: 'balance',
      width:150,
      render:(value:any,record:any)=><InputNumber defaultValue={record.balance/100} style={{width:'100%'}} precision={2}  onChange={(balance:any)=>record.balance=balance*100}/>
    },
  ]

  /* 接口参数 */
  const dataRow = ():any => ({
    params: searchParams,
    axApi: xjllAnalyse
  })
  const uniCompanyData:any = {
    axApi: getCompanyList,
    setList: setCompanyList,
  }
  const uniSubjectData:AxDataform = {
    // params: {filter_EQL_level:1},
    params: {page:1, size:999},
    axApi: axSubjectList,
    itemModify:{modifyFunc:({item}:any)=>item.title=item.subjectNo+' '+item.name},
    setList: setSubjectList
  }
  //添加现金流和会计科目对应关系
  const dataCreateCashflowSubject:any = {
    axApi: axCreateCashflowSubject,
    awaitFunc: ()=>uniAxList({...dataRow(),setTableLoading:setSubjectTableLoading,awaitFunc:(predata:any)=>{
      let prerowlist = predata.response
      let tempcurrent:any = uniFilterItem(prerowlist,'id',currentRow.id)
      currentRow.detail = tempcurrent.detail
      setSubjectModalControl(()=>({...subjectModalControl,subjectList:tempcurrent.detail}))
    }})
  }
  //删除现金流和会计科目对应关系
  const dataDeleteCashflowSubject:any = {
    axApi: axDeleteCashflowSubject,
    awaitFunc: ()=>uniAxList({...dataRow(),setTableLoading:setSubjectTableLoading,awaitFunc:(predata:any)=>{
      let prerowlist = predata.response
      let tempcurrent:any = uniFilterItem(prerowlist,'id',currentRow.id)
      currentRow.detail = tempcurrent.detail
      setSubjectModalControl(()=>({...subjectModalControl,subjectList:tempcurrent.detail}))
    }})
  }
  //分公司查询本月金额
  const dataCompanyFlowList:any = {
    axApi: axCompanyFlow,
    setList: setCompanyFlowListInit,
    setTableLoading:setAdjustTableLoading,
  }
  //创建流量调整
  const dataCreateFlowAdjust:any = {
    axApi: axcreateFlowAdjust,
    setModalLoading,
    setModal:setAdjustModal,
  }
  //更新流量调整
  const dataUpdateFlowAdjust:any = {
    axApi: axupdateFlowAdjust,
    setModalLoading,
    setModal:setAdjustModal
  }
  //本年累计金额
  const dataYearFlowAdjustSum:any={
    axApi:axYearFlowAdjustsum
  }
  //本期调整金额
  const dataFlowAdjustsum:any = {
    params:tempSearchParams,
    axApi: axFlowAdjustsum,
    setTableLoading,
    finishLoading:false,
    awaitFunc: (currentFlows:any)=> {
      let beginYm = tempSearchParams.year+'-01'
      let endYm:any
      if (mainControl.selectType===1) endYm =tempSearchParams.ym
      else {
        if (tempSearchParams.number = 1) endYm = tempSearchParams.year+'-03'
        else if (tempSearchParams.number = 2) endYm = tempSearchParams.year+'-06'
        else if (tempSearchParams.number = 3) endYm = tempSearchParams.year+'-09'
        else endYm = tempSearchParams.year+'-12'
      }
      uniAxList({...dataYearFlowAdjustSum,params:{beginYm,endYm},awaitFunc:(yearFlows:any)=>uniAxList({...dataRow(),awaitFunc: (predata:any)=>{
        let prerowlist = predata.response.filter((item:any)=>item.calculate!==3)
        let count = 1
        let topRow1:any = {id:-1,name:'一、经营活动产生的现金流量',monthPrice:0,yearPrice:0}
        let topRow2:any = {id:-2,name:'二、投资活动产生的现金流量',monthPrice:0,yearPrice:0}
        let topRow3:any = {id:-3,name:'三、筹资活动产生的现金流量',monthPrice:0,yearPrice:0}
        let topRow4:any = {id:-4,name:'四、现金净增加额',monthPrice:0,yearPrice:0}
        let topRow5:any = {id:-5,name:'加：期初现金余额',monthPrice:predata.beginPrice || 0,yearPrice:predata.yearBeginPrice || 0}
        let topRow6:any = {id:-6,name:'五、期末现金余额',monthPrice:predata.endPrice || 0,yearPrice:predata.yearEndPrice || 0}
        prerowlist.forEach((prerowitem:any)=>{
          let findCurrenAdjust = currentFlows.find((currentItem:any)=>currentItem.cashFlowId === prerowitem.id)
          let findYearAdjust = yearFlows.find((yearItem:any)=>yearItem.cashFlowId === prerowitem.id)
          if (findCurrenAdjust) prerowitem.monthPrice += findCurrenAdjust.balance
          if (findYearAdjust) prerowitem.yearPrice += findCurrenAdjust.balance
          if (prerowitem.calculate===1) {
            topRow4.monthPrice += prerowitem.monthPrice
            topRow4.yearPrice += prerowitem.yearPrice
          } else {
            topRow4.monthPrice -= prerowitem.monthPrice
            topRow4.yearPrice -= prerowitem.yearPrice
          }
          let topRow
          if (prerowitem.type==='1') topRow = topRow1
          else if (prerowitem.type==='2') topRow = topRow2
          else if (prerowitem.type==='3') topRow = topRow3
          if (topRow) {
            prerowitem.count = count
            count++
            if (prerowitem.calculate===1) {
              topRow.monthPrice += prerowitem.monthPrice
              topRow.yearPrice += prerowitem.yearPrice
            } else {
              topRow.monthPrice -= prerowitem.monthPrice
              topRow.yearPrice -= prerowitem.yearPrice
            }
            if (topRow.children) topRow.children.push(prerowitem)
            else topRow.children = [prerowitem]
          }
        })
        topRow4.count = count
        topRow5.count = count + 1
        topRow6.count = count + 2
        if (topRow4.monthPrice+topRow5.monthPrice===topRow6.monthPrice && topRow4.yearPrice+topRow5.yearPrice===topRow6.yearPrice) mainControl.isBalance = true
        
        let namarr = ['经营','投资','筹资']
        let curid = -7
        let temparr = [topRow1,topRow2,topRow3]
        temparr.forEach((item:any,index:any)=>{
          item.monthPrice = null
          item.yearPrice = null
          let curcaculate = 1
          let newchildren = []
          if (item.children) {
            let tempobj = {id: curid, name: '\xA0\xA0\xA0\xA0\xA0\xA0\xA0\xA0\xA0\xA0\xA0\xA0\xA0\xA0\xA0\xA0'+namarr[index]+'活动现金流入小计',monthPrice:0,yearPrice:0}
            curid = curid-1
            let monthInmount = 0
            let yearInmount = 0
            let monthOutmount = 0
            let yearOutmount = 0
            item.children.forEach((subitem:any, subindex:any)=>{
              if (subitem.calculate===1) {
                monthInmount += subitem.monthPrice
                yearInmount += subitem.yearPrice
              } else {
                monthOutmount += subitem.monthPrice
                yearOutmount += subitem.yearPrice
              }
              if (subitem.calculate===2 && curcaculate===1) {
                newchildren.push({...tempobj})
                tempobj = {id: curid, name: '\xA0\xA0\xA0\xA0\xA0\xA0\xA0\xA0\xA0\xA0\xA0\xA0\xA0\xA0\xA0\xA0'+namarr[index]+'活动现金流出小计',monthPrice:0,yearPrice:0}
                curid = curid-1
                curcaculate = 2
              }
              tempobj.monthPrice += subitem.monthPrice
              tempobj.yearPrice += subitem.yearPrice
              newchildren.push({...subitem})
            })
            newchildren.push({...tempobj})
            newchildren.push({id:curid, name: namarr[index]+'活动产生的现金流量净额',monthPrice: monthInmount - monthOutmount,yearPrice:yearInmount - yearOutmount})
            curid -= 1
            item.monthPrice = ''
            item.yearPrice = ''
            item.children = newchildren
          }
        })
        setRowList(()=>[...temparr,topRow4,topRow5,topRow6])
        setTableLoading(()=>false)
      }})})
    }
  }
  /* useEffect */
  //监听路由变化
  React.useEffect(() => {
    if (loadFlag && location.pathname===pathname) routerFlash()
  }, [location])
  /* mounted */
  React.useEffect(() => {
    // uniAxList(dataFlowAdjustsum)
    // uniAxList(dataRow())
    uniAxList(uniCompanyData)
    uniAxList(uniSubjectData)
    setLoadFlag(()=>true)
  }, [])

  return (
    <>
    <TopSearch
      ref={searchRef}
      topActions={topActions}
      searchList={searchList}
      searchConfirm={searchConfirm}
    />
      <div id="tabletest" className={tablecss.detailtable}>
        <Table
          ref={tableref}
          size='small'
          rowKey={'id'}
          columns={columns}
          // rowSelection={{ ...uniSelectRows(setSelectedRowKeys, setSelectedRows), checkStrictly:true }}
          dataSource={rowList}
          pagination={false}
          scroll={{y:'calc(100vh - 232px)'}}
          loading={tableLoading}
          expandable={{defaultExpandedRowKeys:[-1,-2,-3]}}
          sticky
        />
      </div>
      <Modal
        width={800}
        maskClosable={false}
        title={'编辑公式-'+currentRow.name}
        visible={subjectModal}
        onCancel={()=>{
          setSubjectModal(false)
        }}
        destroyOnClose
        footer={[<Button key="back" onClick={()=>{
          setSubjectModal(false)
        }}>取消</Button>]}
      >
        {/* <Select
          showSearch
          optionFilterProp='children'
          allowClear
          style={{ width: 200 }}
          placeholder="会计科目"
          onChange={(value:any,operation:any)=>{
            setSubjectModalControl(()=>({...subjectModalControl,selectSubjectId:value,selectSubjectNo:value?operation.children.split(' ')[0]:null,selectSubjectName:value?operation.children.split(' ')[1]:null}))
          }}
        > 
          {subjectList.map((item:any) => <Select.Option key={item.id} value={item.id}>{item.subjectNo+' '+item.name}</Select.Option>)}
        </Select>&nbsp; */}
        <TreeSelect
          showSearch
          treeNodeFilterProp='title'
          fieldNames={{value: 'id'} as any}
          style={{ width: 200 }}
          dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
          placeholder="会计科目"
          treeData={subjectList}
          onChange={(value, children:any, record) => {
            let temparr = children[0].split(' ')
            setSubjectModalControl(()=>({...subjectModalControl,selectSubjectId:value,selectSubjectNo:temparr[0],selectSubjectName:temparr[1]}))
          }}
        />&nbsp;
        <Select
          allowClear
          style={{ width: 200 }}
          placeholder="运算符号"
          onChange={(value) => setSubjectModalControl(()=>({...subjectModalControl,selectCalculate:value}))}
        > 
          {calculateTypes.map((item:any) => <Select.Option key={item.value} value={item.value}>{item.name}</Select.Option>)}
        </Select>&nbsp;
        <Select
          allowClear
          style={{ width: 200 }}
          placeholder="取数规则"
          onChange={(value) => setSubjectModalControl(()=>({...subjectModalControl,selectCalculateType:value}))}
        > 
          {calculateTypeTypes.map((item:any) => <Select.Option key={item.value} value={item.value}>{item.name}</Select.Option>)}
        </Select>&nbsp;
        <Button loading={modalLoading} onClick={() => {
          if (subjectModalControl.selectSubjectId && subjectModalControl.selectCalculate && subjectModalControl.selectCalculateType) {
            uniAxList({...dataCreateCashflowSubject,params:{
              calculate:subjectModalControl.selectCalculate,
              calculateType:subjectModalControl.selectCalculateType,
              cashFlowId:currentRow.id,
              cashFlowName:currentRow.name,
              subjectId:subjectModalControl.selectSubjectId,
              subjectName:subjectModalControl.selectSubjectName,
              subjectNo:subjectModalControl.selectSubjectNo
            }})
          }
        }} type='primary'>添加</Button>
        <p></p>
        <Table
          size='small'
          rowKey={'cashFlowSubjectId'}
          columns={columnsInSubjectModal}
          dataSource={subjectModalControl.subjectList}
          pagination={false}
          loading={subjectTableLoading}
          sticky
        />
      </Modal>
      <Modal
        className={modalcss.fixheight}
        visible={adjustModal}
        title="调整本月金额"
        destroyOnClose
        onCancel={()=>setAdjustModal(()=>false)}
        footer={[<Button key="back" onClick={()=>setAdjustModal(false)}>取消</Button>, <Button loading={modalLoading} key="submit" type="primary" onClick={() => {
          console.log('companflowlist--------',companyFlowList)
          let creatArr:any = []
          let updateArr:any = []
          companyFlowList.forEach((item:any)=>{
            if (item.id) updateArr.push(item)
            else creatArr.push(item)
          })
          if(creatArr.length === 0) uniAxList({...dataUpdateFlowAdjust,params:updateArr,awaitFunc:()=>uniAxList(dataFlowAdjustsum)})
          else if(updateArr.length === 0) uniAxList({...dataCreateFlowAdjust,params:creatArr,awaitFunc:()=>uniAxList(dataFlowAdjustsum)})
          else {
            uniAxList({...dataCreateFlowAdjust,params:creatArr,finishLoading:false,awaitFunc:()=>{
              uniAxList({...dataUpdateFlowAdjust,params:updateArr,awaitFunc:()=>uniAxList(dataFlowAdjustsum)})
            }})
          }
        }}>确定</Button>]}
      >
        <div className={tablecss.default12}>
          <div style={{display:'flex',justifyContent:'space-between'}}>
            <span><b>项目:</b> {currentRow.name}</span>
            <span><b>本月金额:</b> {(currentRow.monthPrice/100).toFixed(2)}</span>
          </div>
          <p></p>
          <Table
            rowKey={'companyId'}
            loading={adjustTableLoading}
            size='small'
            columns={adjustColumns}
            dataSource={companyFlowList}
            pagination={false}
          />
        </div>
      </Modal>
    </>
  )
}
