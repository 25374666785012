import React, { createRef, useCallback, useRef, useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Checkbox, Form, FormInstance, Input, Modal, Radio, Typography, message } from 'antd';

import logincss from '../css/login.module.less';
import { StaffItem, UserLocal } from '../utils/beans';
import { initUserLocal } from '../utils/constant';
import { getLocalItem, getUserLocal, getUserToken, setLocalItem } from '../utils/localstorage';
import { axSendmsg, axforget, getStaffList, getStaffMenu, login, loginUserDetail } from '../utils/api';
import { setUser, setLoadFunc, setAuth } from '../utils/store';
import { AxDataform, FormItemObj } from '../utils/interfaces';
import { uniAxList } from '../utils/uniFucs';
import FormDialog from '../components/FormDialog';

export default function Login() {
const modalRef = createRef<FormInstance>()
const [showModal, setShowModal] = useState(false)
const pathname = "/login"
const dispatch = useDispatch()
const location = useLocation()
const [loginFlag, setLoginFlag] = useState({value:false})
let timer:any
//非刷新属性constructor
let loginUserObj:any = getLocalItem('userLocal', initUserLocal)
let decodeStr = (loginUserObj && loginUserObj.password) ? window.atob(loginUserObj.password) : ''
const initUser:UserLocal = {...loginUserObj, password: (loginUserObj && loginUserObj.password) ? decodeStr.slice(0,decodeStr.length-6) : null}
/* hooks */
const formModalRef = createRef<FormInstance>()
const navigate = useNavigate()
const [loginLoading, setLoginLoading] = useState(false)
const [modalLoading, setModalLoading] = useState(false)
let [formInitials, setFormInitials] = useState({})
const [sendLoading, setSendLoading] = useState(false)
const [waitflag,setWaitflag] = useState(false)
const [waitcount,setWaitcount] = useState(60)
const [formData, setFormData] = useState({})
const [showAccount, setShowAccount] = useState(false)
const [accountList, setAccountList] = useState([])
const [curAccountId, setCurAccountId] = useState(-1)
const [switchLoading, setSwitchLoading] = useState(false)
// dispatch(setLoadFunc(setLoginLoading))

/* elements */
//修改密码modal项
const formItems:FormItemObj[] = [
{label: '手机号',prop: 'mobile',span:24},
{label: '验证码',prop: 'code',span:24,suffix:!waitflag
  ?<Button loading={sendLoading} style={{marginTop:4}} size='small' type="primary" onClick={()=>onsendmsg()}>发送短信</Button>
  :<Button disabled style={{marginTop:4}} size='small' onClick={()=>onsendmsg()}>{waitcount}秒</Button>
},
{label: '新密码',prop: 'newPassword',span:24}
]

  /* funcs */
  //监听按下enter事件
  const getKeydow = (e:any)=>{
    if (e.keyCode===13) {
      setLoginLoading(()=>true)
      formModalRef.current?.submit()
    }
  }
  //点击登录
  const onFinish = async (formVales: any) => {
    setLocalItem('userLocal', { token: '' })
    const resdata = await uniAxList(dataLogin(formVales))
    console.log('restdata=-----',resdata)
    if (resdata.length === 1) saveAndJump(formVales, resdata[0])
    else {
      setAccountList(resdata)
      setFormData(formVales)
      setShowAccount(true)
    }
    setLocalItem('allusers', resdata)
  }
  //保存登录信息并跳转
  const saveAndJump = async (formVales: any, resdata:any) => {
    setSwitchLoading(true)
    //存储登录信息到本地
    let encodeStr = window.btoa(formVales.password+formVales.password.slice(2,5)+formVales.password.slice(1,4))
    // dispatch(setUser({ mobile: formVales.mobile, password: formVales.password+formVales.password.slice(2,5)+formVales.password.slice(1,4), remember: formVales.remember, autoLogin: formVales.autoLogin, token: response.data+response.data.slice(2,5) }))
    setLocalItem('userLocal', { mobile: formVales.mobile, password: encodeStr, remember: formVales.remember, autoLogin: formVales.autoLogin, token: resdata.token+resdata.token.slice(2,5) })
    //获取用户的menuTree
    const resmenu = await uniAxList(dataLoginMenu)
    setLocalItem('userMenu', resmenu)
    setLocalItem('expandKeys', [])
    const resdetail = await uniAxList(dataLoginDetail)
    let userInfo = resdetail.user
    setLocalItem('userLocal', {
      ...userInfo, mobile: formVales.mobile, password: encodeStr, remember: formVales.remember, 
      autoLogin: formVales.autoLogin, token: resdata.token+resdata.token.slice(2,5), groupItemName: resdata.groupItemName,
      positionId: resdetail.positionId, positionKey:resdetail.positionKey,positionName:resdetail.positionName
    })
    // loginFlag.value = true
    //跳转到欢迎页-跳转到/indexpage时，页面可能不加载
    setSwitchLoading(false)
    setShowAccount(false)
    navigate('/indexpage', {replace:true})
    // window.location.reload() // 解决navigate('/indexpage')时main页面不加载的问题
  }
  //自动登陆
  const autoLogin = async () => {
    const loginUser:StaffItem = getUserLocal()
    if (loginUser && loginUser.mobile && loginUser.password && loginUser.autoLogin) {
      setLocalItem('userLocal', { token: '' })
      setLoginLoading(()=>true)
      let decodeStr = window.atob(loginUser.password).slice(0,loginUser.password.length-6)
      const response = await login({mobile:loginUser.mobile, password:decodeStr})
      if (response && response.message && response.message === 'success') {
        //存储登录信息到本地
        setLocalItem('userLocal', { mobile: loginUser.mobile, password: loginUser.password, remember: loginUser.remember, autoLogin: loginUser.autoLogin, token: response.data+response.data.slice(2,5) })
        //获取用户的menuTree
        const res = await getStaffMenu({})
        setLocalItem('userMenu', res.data)
        setLocalItem('expandKeys', [])
        //获取用户详情
        const userInfo = await getStaffList({filter_EQS_mobile: loginUser.mobile})
        //存储登录信息到本地
        setLocalItem('userLocal', {...userInfo.data[0], mobile: loginUser.mobile, password: loginUser.password, remember: loginUser.remember, autoLogin: loginUser.autoLogin, token: response.data+response.data.slice(2,5),})
        setLoginLoading(()=>false)
        //跳转到欢迎页-跳转到/indexpage时，页面可能不加载
        navigate('/indexpage', {replace:true})
        // window.location.reload() // 解决navigate('/indexpage')时main页面不加载的问题
      }
    }
  }

  //点击忘记密码
  const onforget = () => {
    let intimobile = formModalRef.current?.getFieldValue('mobile')
    if (intimobile) setFormInitials({mobile:intimobile})
    setShowModal(true)
  }
  //modal点击确定
const modalConfirm = async (formValues:any) => {
  uniAxList(dataForget(formValues))
  // modalFlag==='new'?uniAxList(dataNewrow(await uniFormProcess(formValues,formItems))):uniAxList(dataUpdaterow(await uniFormProcess(formValues,formItems)))
}
//modal取消
const modalCancel = () => {
  setShowModal(()=>false)
  setFormInitials({})
}
//点击发送短信
const onsendmsg = () => {
  let tempmobile = modalRef.current?.getFieldValue('mobile')
  if (!tempmobile) message.error('请填写手机号')
  uniAxList(dataSendmsg({mobile:tempmobile}))
}

  //监听路由变化
  React.useEffect(() => {
    if (location.pathname===pathname && getUserToken()) navigate('/indexpage', {replace:true})
  }, [location])
  /* mounted */
  React.useEffect(() => {
    window.addEventListener('keydown', getKeydow)
    return () => window.removeEventListener('keydown', getKeydow);
  }, [])

  /* 接口参数 */
const dataLogin = (formVales:any):AxDataform => ({
  params: {mobile:formVales.mobile, password:formVales.password},
  axApi: login,
  setModalLoading: setLoginLoading,
  finishLoading:false
})
//短信修改密码
const dataForget = (formVales:any):AxDataform => ({
  params: formVales,
  axApi: axforget,
  setModalLoading,
  setShowModal,
  awaitFunc: () => {
    message.success('操作成功,请重新登录')
    setWaitflag(false)
    setWaitcount(60)
    if (timer) clearInterval(timer)
  }
})
//发送短信
const dataSendmsg = (formVales:any):AxDataform => ({
  params: formVales,
  axApi: axSendmsg,
  setModalLoading:setSendLoading,
  finishLoading:false,
  awaitFunc: () => {
    let count = 60
    setWaitflag(true)
    timer = setInterval(() => {
      if (count===0) {
        clearInterval(timer)
        setWaitflag(false)
        setWaitcount(60)
      }
      if (count===59) setSendLoading(false) 
      count--
      setWaitcount(count)
    }, 1000)
  }
})
//获取用户菜单
const dataLoginMenu:AxDataform = {
  axApi: getStaffMenu
}
//获取用户详情
const dataLoginDetail:AxDataform = {
  axApi: loginUserDetail,
  setModalLoading: setLoginLoading
}
//登录用户详情


return (<>
  <div className={logincss.login}>
    <div className={logincss.logowrap}>
      <div className={logincss.left}></div>
      <div className={logincss.right}>
        {/* 登录表单 */}
        <Form ref={formModalRef} name="normal_login" className={logincss.loginform} initialValues={initUser.remember?initUser:{}} onFinish={(formVales)=>onFinish(formVales)}>
          <div className={logincss.header}>
            <div>欢迎使用</div>
            <div>禾美集配业财系统</div>
            <p></p>
          </div>
          {/* 姓名 */}
          <Form.Item name="mobile" rules={[{ required: true, message: '输入手机号!' }]}>
            <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="手机号"/>
          </Form.Item>
          {/* 密码 */}
          <Form.Item name="password" rules={[{ required: true, message: '输入密码!' }]}>
            <Input className={logincss.antdinput} prefix={<LockOutlined className="site-form-item-icon" />} type="password" placeholder="密码"/>
          </Form.Item>
          {/* 记住我 */}
          <div style={{display:'flex',justifyContent:'space-between'}}>
            <Form.Item>
              <Form.Item name="remember" valuePropName="checked" noStyle>
                <Checkbox className={logincss.mycheckbox}>记住密码</Checkbox>
              </Form.Item>
              {/* <a className="login-form-forgot" href="">Forgot password</a> */}
            </Form.Item>
            {/* <Form.Item>
              <Form.Item name="autoLogin" valuePropName="checked" noStyle>
                <Checkbox className={logincss.mycheckbox}>自动登陆</Checkbox>
              </Form.Item>
            </Form.Item> */}
          </div>
          {/* 登录按钮 */}
          <Form.Item>
            <div className={logincss.loginbutton}>
              <Button style={{width:'100%'}} type="primary" htmlType="submit" loading={loginLoading} onClick={()=>console.log('hello')}>登录</Button>
            </div>
            <p></p>
            <div style={{display:'flex',justifyContent:'space-between'}}>
              <NavLink to={''} onClick={onforget}><span className={logincss.footer}>忘记密码</span></NavLink>
              <NavLink to={''}><span className={logincss.footer}>立即注册</span></NavLink>
            </div>
            {/* <a href="">register now!</a> */}
          </Form.Item>
        </Form>
      </div>
    </div>
  </div>
  <FormDialog
    ref={modalRef}
    title="重置密码"
    show={showModal}
    formItems={formItems}
    modalLoading={modalLoading}
    formInitials={formInitials}
    cancel={modalCancel}
    confirm={modalConfirm}
  />
  <Modal
    width={600}
    maskClosable={false}
    title="选择登录账号"
    open={showAccount}
    onOk={() => {
      let finditem = accountList.find((item:any)=>item.userId===curAccountId)
      saveAndJump(formData, finditem)
    }}
    onCancel={()=>{
      setShowAccount(false)
      setLoginLoading(false)
    }}
    afterClose={()=>{
      setShowAccount(false)
      setLoginLoading(false)
    }}
    destroyOnClose
    footer={[<Button key="back" onClick={()=>{
      setShowAccount(false)
      setLoginLoading(false)
    }}>取消</Button>, <Button loading={switchLoading} type="primary" onClick={() => {
      let finditem = accountList.find((item:any)=>item.userId===curAccountId)
      saveAndJump(formData, finditem)
    }}>确定</Button>]}
  >
    <Radio.Group size="large" onChange={(event) => setCurAccountId(event.target.value)}>
      {
        accountList.map((item:any)=><Radio key={item.userId} value={item.userId}>{item.userName+'-'+item.groupItemName}</Radio>)
      }
    </Radio.Group>
  </Modal>
</>);
}
