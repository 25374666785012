import React, { createRef, useRef, useState } from 'react'
import { useLocation, useNavigate, useOutlet } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Button, FormInstance, MenuProps, message, Select, Spin } from 'antd'
import { Layout, Menu, Tabs, Popover, Modal, Form, Input } from 'antd'
import { UserOutlined, MoreOutlined } from '@ant-design/icons'

import indexpagecss from '../css/indexpage.module.less'
import { commonMenuItems, initUserLocal, menuObj } from '../utils/constant'
import { TabItem,  MenuTreeItem } from '../utils/beans'
import { getLocalItem, setLocalItem, getUserMobile, getUserPasswd, getUserLocal, getUserName } from '../utils/localstorage'
import { changePasswd, getStaffList, getStaffMenu, loginUserDetail, userLoginOut } from '../utils/api'
import store, { setUser } from '../utils/store'
import DraggableTabs from '../components/DraggableTabs'
import { uniAxList, uniFilterItem, uniModifyAxList } from '../utils/uniFucs'
import { AxDataform } from '../utils/interfaces'

export default function IndexPage() {
  let currentTreeListItem:any
  /* 不需要刷新页面的参数constructor */
  const treeList = [...commonMenuItems,...getLocalItem('userMenu', [{code:'PC_GUIDE',name:'新手指引'}])]
  const { Header, Content, Footer, Sider } = Layout
  const initTabItem: TabItem[] = []
  const initAcccountList:any = getLocalItem('allusers', [])
  const userinfo:any = getLocalItem('userLocal', {})

  /* hooks */
  const chgPasswdRef = createRef<FormInstance>()
  let location:any = useLocation()
  const navigate = useNavigate()
  // const dispatch = useDispatch()
  const outlet = useOutlet()
  // const {userStore: {userInfor}} = useSelector((state:any )=> state)
  const [pageName, setPageName] = useState('首页') //当前tab标签名字
  const [tabItems, setTabItems] = useState(initTabItem) //tab标签数组
  const [activeKey, setActiveKey] = useState('') //当前选中tab标签
  const [passwdModal, setPasswdModal] = useState(false) //修改密码遮罩
  const [expandKeys, setExpandKeys] = useState([])
  const [userName, setuserName] = useState({value:''})
  const [spinning, setSpinning] = useState(false)

  /* funcs */
  //根据route获取label
  const getLable = (routeKey: string, sourceList: MenuTreeItem[]) => {
    sourceList.find(item => {
      if (routeKey === item.route) return currentTreeListItem = item
      else if (item.children) getLable(routeKey, item.children)
      return false
    })
  }
  //删除tab标签
  const onEdit = (targetKey: string | React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element> , action: 'add' | 'remove') => {
    remove(targetKey);
    if (targetKey === activeKey) {
      if (tabItems[1] && tabItems[1].route) navigate(tabItems[tabItems.length-2].route, {replace: true})
      else navigate('main', {replace: true})
    }
  }
  const remove = (targetKey: string | React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>) => {
    const targetIndex = tabItems.findIndex((pane) => pane.key === targetKey);
    const newPanes = tabItems.filter((pane) => pane.key !== targetKey);
    if (newPanes.length && targetKey === activeKey) {
      const { key } = newPanes[targetIndex === newPanes.length ? targetIndex - 1 : targetIndex];
      setActiveKey(() => key);
    }
    setTabItems(() => newPanes);
  }
  //tab标签改变
  const tabChange = (key: string) => {
    setActiveKey(() => key)
    let currentTab:any = tabItems.find(item => key === item.key)
    setLocalItem('expandKeys', currentTab.expandKeys)
    navigate(currentTab!.route, {replace: true})
  }
  //用户退出
  const logOut = () => {
    userLoginOut().then((res:any)=>{
      setLocalItem('userLocal', { ...getUserLocal(), token: '', autoLogin: false })
      navigate('/login', {replace: true})
    })
  }
  // 修改密码表单提交
  const chgPasswdFormSubmit = async (formValus:any) => {
    let localUser = getUserLocal()
    let decodeStr = window.atob(localUser.password)
    let decodePwd = decodeStr.slice(0,decodeStr.length-6)
    if (formValus.oldPassword !== decodePwd) message.error('原密码不正确')
    else {
      const res = await changePasswd({newPassword:formValus.password, oldPassword:formValus.oldPassword})
      if (res && res.message && res.message === 'success') {
        setPasswdModal(() => false)
        message.success('密码修改成功')
        setLocalItem('userLocal', { ...localUser, password: window.btoa(formValus.password+formValus.password.slice(2,5)+formValus.password.slice(1,4)),})
      }
    }
  }
  //确认修改密码
  const chgPasswdConfirm = () => {
    chgPasswdRef.current?.submit()
  }
  const saveAndJump = async (userId: any) => {
    setSpinning(true)
    let resdata = initAcccountList.find((item:any)=>item.userId === userId)
    //存储登录信息到本地
    setLocalItem('userLocal', { mobile: userinfo.mobile, password: userinfo.password, remember: userinfo.remember, autoLogin: userinfo.autoLogin, token: resdata.token+resdata.token.slice(2,5) })
    //获取用户的menuTree
    const resmenu = await uniAxList(dataLoginMenu)
    setLocalItem('userMenu', resmenu)
    setLocalItem('expandKeys', [])
    const resdetail = await uniAxList(dataLoginDetail)
    setSpinning(false)
    let userInfo = resdetail.user
    setLocalItem('userLocal', {
      ...userInfo, mobile: userinfo.mobile, password: userinfo.password, remember: userinfo.remember, 
      autoLogin: userinfo.autoLogin, token: resdata.token+resdata.token.slice(2,5), groupItemName: resdata.groupItemName,
      positionId: resdetail.positionId, positionKey:resdetail.positionKey,positionName:resdetail.positionName
    })
    // loginFlag.value = true
    //跳转到欢迎页-跳转到/indexpage时，页面可能不加载
    navigate('/indexpage', {replace:true})
    // window.location.reload() // 解决navigate('/indexpage')时main页面不加载的问题
  }

  /* elements */
  //修饰左侧项目功能菜单
  const menuItems:any = treeList.map((item:MenuTreeItem ,index:number) => {
      item.expandKeys = []
      item.route = menuObj[item.code] ? menuObj[item.code].route : ''
      return {
        key: item.code,
        icon: React.createElement(menuObj[item.code].icon),
        label: item.name,
        onClick: () => {
          if (item.route && item.route !== location.pathname.split('/')[2]) navigate(item.route, {replace: false})
        },
        children: (item.children && item.children.length)
          ? item.children.map((subitem:MenuTreeItem, subindex:number) => {
            subitem.route = menuObj[subitem.code] ? menuObj[subitem.code].route : ''
            subitem.expandKeys = [item.code]
            return {
              key: subitem.code,
              icon: React.createElement(menuObj[subitem.code] ? menuObj[subitem.code].icon : null),
              label: subitem.name,
              onClick: () => {
                if (subitem.route && subitem.route !== location.pathname.split('/')[2]) {
                  navigate(subitem.route, {replace: false})
                  setLocalItem('expandKeys', subitem.expandKeys)
                }
              },
              children: subitem.children && subitem.children.length
                ? subitem.children.map((deepitem:MenuTreeItem, deepindex) => {
                  deepitem.route = menuObj[deepitem.code] ? menuObj[deepitem.code].route : ''
                  deepitem.expandKeys = [item.code, subitem.code]
                  return {
                    key: deepitem.code,
                    icon: React.createElement(menuObj[deepitem.code] ? menuObj[deepitem.code].icon : null),
                    label: deepitem.name,
                    onClick: () => {
                      if (deepitem.route && deepitem.route !== location.pathname.split('/')[2]) {
                        navigate(deepitem.route, {replace: false})
                        setLocalItem('expandKeys', deepitem.expandKeys)
                      }
                    }
                  }
                }
              ): null
            }
          }
        ) : null
      }
    })
  //用户名下的功能
  const underName = (
    <div>
      <p className={indexpagecss.undername} onClick={() => setPasswdModal(true)}>修改密码</p>
      <p className={indexpagecss.undername} onClick={() => logOut()}>退出登录</p>
    </div>
  )

  /* 接口参数 */
  //获取用户菜单
  const dataLoginMenu:AxDataform = {
    axApi: getStaffMenu
  }
  //获取用户详情
  const dataLoginDetail:AxDataform = {
    axApi: loginUserDetail,
  }

  /* useEffect */
  //监听路由变化
  React.useEffect(() => {
    let currentRouteSplit:string[] = location.pathname.split('/')
    let currentRoute = currentRouteSplit[currentRouteSplit.length-1]
    console.log('currenroute', currentRoute)
    if (currentRoute !== 'main' && currentRoute !== 'indexpage' && currentRoute !== '' && currentRoute !== 'login') {
      getLable(currentRoute, treeList)
      setTabItems(tabItems => {
        if(tabItems.find(item => currentRoute === item.route)) {}
        else tabItems.push({name: currentTreeListItem.name, key: currentTreeListItem.code, expandKeys: currentTreeListItem.expandKeys, route: currentRoute, page: outlet})
        setActiveKey(() => currentTreeListItem.code)
        return tabItems
      })
      setPageName(() => currentTreeListItem.code)
    } else {
      setPageName(() => '首页')
      setActiveKey(() => '首页')
    }
  }, [location]);

  return (
    <>
      <Spin spinning={spinning} size="large" tip="切换中...">
      <Layout style={{maxWidth:'100%'}}>
        <Header className={indexpagecss.header}>
          {/* <h1>共配财务系统</h1> */}
          <img height={40} src={require('../materials/img/logo.png')}></img>
          <div style={{display: 'flex', alignItems:'center'}}>
            {/* <div style={{color:'#fff',fontSize:'16px'}}><UserOutlined />&nbsp;{getUserName()}</div> */}
            <UserOutlined style={{color:'#fff',fontSize:'16px'}} />&nbsp;
            <Select
              defaultValue={userinfo.id}
              size="small"
              allowClear={false}
              onChange={(value,operation)=>saveAndJump(value)}
            > 
              {initAcccountList.map((listitem:any) => <Select.Option label={listitem.userName} key={listitem.userId} value={listitem.userId}>{listitem.userName+'-'+listitem.groupItemName}</Select.Option>)}
            </Select>
            <Popover content={underName}>
              <MoreOutlined style={{color:'#fff',fontSize:'20px',fontWeight:'bold',marginLeft:20}} />
            </Popover>
          </div>
        </Header>
        <Layout>
          <Sider className={indexpagecss.sidebar} width={210}>
              <Menu className={indexpagecss.menu} defaultSelectedKeys={['首页']} selectedKeys={[activeKey]} defaultOpenKeys={getLocalItem('expandKeys',[])} mode="inline" items={menuItems} />
          </Sider>
          <Content className={indexpagecss.content}>
            {
            pageName === '首页' 
            ? outlet
            : (
              <>
                <DraggableTabs style={{height:'100%'}} hideAdd onChange={tabChange} onEdit={onEdit} activeKey={activeKey} type="editable-card">
                  {tabItems.map(pane => (
                    <Tabs.TabPane style={{height:'100%',width:'100%'}} tab={pane.name} key={pane.key}>
                      <div style={{height:'100%',boxSizing:'border-box',padding:'5px 10px',display:'flex',flexDirection:'column'}}>{pane.page}</div>
                    </Tabs.TabPane>
                  ))}
                </DraggableTabs>
                <div style={{position:'absolute',top:'6.1vh',right:20}}><Button type='default' onClick={()=>{
                  let currenItem:any = [uniFilterItem(tabItems,'key',activeKey)]
                  setTabItems(()=>currenItem)
                }}>关闭非当前页</Button></div>
              </>
              )
            }
          </Content>
        </Layout>
      </Layout>
      </Spin>
      <Modal title="修改密码" visible={passwdModal} onOk={chgPasswdConfirm} onCancel={()=>{setPasswdModal(false)}} destroyOnClose>
        <Form ref={chgPasswdRef} name="changPasswd" labelCol={{ span: 4 }} wrapperCol={{ span: 16 }} onFinish={chgPasswdFormSubmit} autoComplete="off">
          <Form.Item label="账号" name="userName"><span>{getUserMobile()}</span></Form.Item>
          <Form.Item label="原密码" name="oldPassword" rules={[{ required: true, message: '原密码不能为空' }]}><Input.Password placeholder="请输入原密码"/></Form.Item>
          <Form.Item label="新密码" name="password" rules={[{ required: true, message: '新密码不能为空!' }]} hasFeedback><Input.Password placeholder="请输入新密码"/></Form.Item>
          <Form.Item label="确认密码" name="confirm" dependencies={['password']} hasFeedback rules={[
            {required: true, message: '确认密码不能为空'},
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) return Promise.resolve();
                return Promise.reject(new Error('确认密码与新密码不一致'))
              }
            })
          ]}><Input.Password placeholder="请再次输入新密码"/></Form.Item>
        </Form>
      </Modal>
    </>
  )
}
