import axiosHttp from './axiosHttp'
import { LoginUser } from './beans'

/**
 *后端请求api
 */

//用户登录
export const login = (data:object) => {
  return axiosHttp({
    url: '/xbfs/sys/user/login',
    method: 'post',
    data
  }) as unknown as LoginUser
}
//短信修改密码
export const axforget = (data:object) => {
  return axiosHttp({
    url: '/xbfs/sys/user/changePwdWithMessageCode',
    method: 'post',
    data
  }) as unknown as LoginUser
}
//发送短信
export const axSendmsg = (data:object) => {
  return axiosHttp({
    url: '/xbfs/sys/user/sendMessageCode',
    method: 'post',
    data
  }) as unknown as LoginUser
}
//登录用户详情
export const loginUserDetail = () => {
  return axiosHttp({
    url: '/xbfs/sys/user/userContext',
    method: 'get'
  }) as unknown as any
}
//用户退出
export const userLoginOut = () => {
  return axiosHttp({
    url: '/xbfs/sys/user/logout',
    method: 'post'
  }) as unknown as any
}

//修改密码
export const changePasswd = (data:object) => {
  return axiosHttp({
    url: '/xbfs/sys/user/changePwd',
    method: 'post',
    data
  }) as unknown as any
}

/* 组织管理-部门设置 */
//树状图查询部门的数据
export const getDepTree = (params:object) => {
  return axiosHttp({
    // url: '/xbfs/sys/department/tree',
    url: '/xbfs/sys/systemGroupItem/department/list',
    method: 'get',
    params
  }) as unknown as any
}
//允许录入凭证未来期数
export const axAllowmonth = (data:any) => {
  return axiosHttp({
    url: '/xbfs/sys/systemGroupItem/updateAllowMonth',
    method: 'post',
    data
  }) as unknown as any
}

//条件查询部门的数据
export const searchDepList = (params:object) => {
  return axiosHttp({
    url: '/xbfs/sys/department/list',
    method: 'get',
    params
  }) as unknown as any
}

//创建部门
export const createDep = (data:any) => {
  return axiosHttp({
    url: '/xbfs/sys/systemGroupItem/create',
    method: 'post',
    data: JSON.stringify(data)
  }) as unknown as any
}

//删除部门
export const deleteDep = (data:any) => {
  return axiosHttp({
    url: `/xbfs/sys/systemGroupItem/delete?id=${data.id}`,
    method: 'post'
  }) as unknown as any
}
//初始化公司
export const axInitDep = (data:any) => {
  return axiosHttp({
    url: `/xbfs/sys/systemGroupItem/initialize?id=${data.id}`,
    method: 'post'
  }) as unknown as any
}
//反初始化公司
export const axUninitDep = (data:any) => {
  return axiosHttp({
    url: `/xbfs/sys/systemGroupItem/unInitialize?id=${data.id}`,
    method: 'post'
  }) as unknown as any
}

//修改部门
export const modifyDep = (data: object) => {
  return axiosHttp({
    url: '/xbfs/sys/systemGroupItem/update',
    method: 'post',
    data
  }) as unknown as any
}
//账套启用
export const axFirmStartym = (data: object) => {
  return axiosHttp({
    url: '/xbfs/sys/systemGroupItem/updateStartYm',
    method: 'post',
    data
  }) as unknown as any
}
//账套初始化
export const axFirmInitial = (data: object) => {
  return axiosHttp({
    url: '/xbfs/sys/systemGroupItem/updateInitialize',
    method: 'post',
    data
  }) as unknown as any
}
/* 首页 */
//首页金额
export const getHomeBalance = (params:any) => {
  let companyList = params ? params.companyId : null
  let suffix = ''
  if (companyList && companyList.length ===1) suffix = '?companyId='+companyList[0]
  else if (companyList && companyList.length > 1) {
    companyList.forEach((key:any,index:number) => {
      if (index === 0) suffix = '?companyId='+key
      else suffix = suffix + '&companyId=' + key
    })
  }
  if (companyList && companyList.length>0 && params && params.ymd) suffix = suffix + '&ymd=' + params.ymd
  else if (params && params.ymd) suffix = suffix + '?ymd=' + params.ymd
  return axiosHttp({
    url: '/xbfs/fin/home/balance'+suffix,
    method: 'get'
  }) as unknown as any
}
//待审批
export const getHomeReview = (params:object) => {
  return axiosHttp({
    url: '/xbfs/fin/home/review',
    method: 'get',
    params
  }) as unknown as any
}
//财务数据
export const getHomeFindata = (params:any) => {
  let companyList = params ? params.companyId : null
  let suffix = ''
  if (companyList && companyList.length ===1) suffix = '?companyId='+companyList[0]
  else if (companyList && companyList.length > 1) {
    companyList.forEach((key:any,index:number) => {
      if (index === 0) suffix = '?companyId='+key
      else suffix = suffix + '&companyId=' + key
    })
  }
  if (companyList && companyList.length>0 && params && params.ym) suffix = suffix + '&ym=' + params.ym
  else if (params && params.ym) suffix = suffix + '?ym=' + params.ym
  return axiosHttp({
    url: '/xbfs/fin/home/finData'+suffix,
    method: 'get'
  }) as unknown as any
}
//月结客户分析
export const getHomeYjdata = (params:object) => {
  return axiosHttp({
    url: '/xbfs/fin/home/yjData',
    method: 'get',
    params
  }) as unknown as any
}
//年度利润分析表
export const getHomeFindataYear = (params:any) => {
  let companyList = params ? params.companyId : null
  let suffix=`?year=${params.year}`
  if (companyList && companyList.length ===1) suffix = `?year=${params.year}&companyId=`+companyList[0]
  else if (companyList && companyList.length > 1) {
    companyList.forEach((key:any,index:number) => suffix = suffix + '&companyId=' + key)
  }
  return axiosHttp({
    url: '/xbfs/fin/home/finData/year'+suffix,
    method: 'get'
  }) as unknown as any
}
/* 职位设置 */
//查询职位列表
export const getPositionList = (params:object) => {
  return axiosHttp({
    url: '/xbfs/sys/position/list',
    method: 'get',
    params
  }) as unknown as any
}

//新建职位
export const createPosition = (data:object) => {
  return axiosHttp({
    url: '/xbfs/sys/position/create',
    method: 'post',
    data
  }) as unknown as any
}

//修改职位
export const updatePosition = (data:object) => {
  return axiosHttp({
    url: '/xbfs/sys/position/update',
    method: 'post',
    data
  }) as unknown as any
}

//删除职位
export const deletePosition = (data: any) => {
  return axiosHttp({
    url: `/xbfs/sys/position/delete?id=${data.id}`,
    method: 'post'
  }) as unknown as any
}

/* 人员管理 */
//人员余额 用户余额日志
export const getUserBillLog = (params:object) => {
  return axiosHttp({
    url: '/xbfs/fin/userBillLog/list',
    method: 'get',
    params
  }) as unknown as any
}
//获取人员列表
export const getStaffList = (params:object) => {
  return axiosHttp({
    // url: '/xbfs/sys/user/list',
    url: '/xbfs/sys/user/detailList',
    method: 'get',
    params
  }) as unknown as any
}
//人员批量导入
export const axUserimport  = (data:object) => {
  return axiosHttp({
    url: '/xbfs/sys/user/import',
    method: 'post',
    data
  }) as unknown as any
}

//获取人员最大工号
export const axStaffmax = () => {
  return axiosHttp({
    url: '/xbfs/sys/user/getMaxJobNum',
    method: 'get',
  }) as unknown as any
}
//人员菜单树状列表
export const getStaffMenu = (params:any) => {
  return axiosHttp({
    url: '/xbfs/sys/user/menu/tree',
    method: 'get',
    params
  }) as unknown as any
}
//获取人员性质列表
export const getStaffCharList = () => {
  return axiosHttp({
    url: '/xbfs/sys/userCharacter/list',
    method: 'get',
  }) as unknown as any
}
//创建人员-新建人员
export const createUser = (data: any) => {
  return axiosHttp({
    url: '/xbfs/sys/user/create',
    method: 'post',
    data
  }) as unknown as any
}
//修改人员
export const updateStaff = (data:any) => {
  return axiosHttp({
    url: '/xbfs/sys/user/update',
    method: 'post',
    data
  }) as unknown as any
}
//删除人员
export const deleteStaff = (data:any) => {
  return axiosHttp({
    url: `/xbfs/sys/user/delete?id=${data.id}`,
    method: 'post'
  }) as unknown as any
}
//按条件查询人员 & 查询用户详细信息
export const getStaffDetailList = (params:any) => {
  return axiosHttp({
    url: '/xbfs/sys/user/detailList',
    method: 'get',
    params
  }) as unknown as any
}
//获取集配人员
export const axJpusers = (params:any) => {
  return axiosHttp({
    url: '/xbfs/sys/user/sync/list',
    method: 'get',
    params
  }) as unknown as any
}
//同步集配人员
export const axSyncJpusers = (data:any) => {
  return axiosHttp({
    url: '/xbfs/sys/user/sync/user?groupItemId='+data.groupItemId,
    method: 'post',
    data: data.users
  }) as unknown as any
}
//员工面单详情
export const getStaffBillList = (params:any) => {
  return axiosHttp({
    url: '/xbfs/materiel/userCompanyBill/list',
    method: 'get',
    params
  }) as unknown as any
}
/* 角色 */
//创建角色
export const createRole = (data:object) => {
  return axiosHttp({
    url: '/xbfs/sys/role/create',
    method: 'post',
    data
  }) as unknown as any
}
//获取角色列表
export const getRoleList = (params:any) => {
  return axiosHttp({
    url: '/xbfs/sys/role/list',
    method: 'get',
    params
  }) as unknown as any
}
//修改角色
export const updateRole = (data:object) => {
  return axiosHttp({
    url: '/xbfs/sys/role/update',
    method: 'post',
    data
  }) as unknown as any
}
//删除角色
export const deleteRole = (data:any) => {
  return axiosHttp({
    url: `/xbfs/sys/role/delete?id=${data.id}`,
    method: 'post',
    data
  }) as unknown as any
}
//查看角色权限
export const getRoleAuth  = (params:any) => {
  return axiosHttp({
    url: '/xbfs/sys/role/menuTreeSelect',
    method: 'get',
    params
  }) as unknown as any
}
//角色授权菜单
export const authMenu = (data:any) => {
  return axiosHttp({
    url: '/xbfs/sys/role/menu/auth',
    method: 'post',
    data
  }) as unknown as any
}


/* 共配套账(公司) */
//查询共配账套的数据
export const getCompanyList = (params:any) => {
  return axiosHttp({
    // url: '/xbfs/fin/company/list',
    url: '/xbfs/sys/systemGroupItem/company/list',
    method: 'get',
    params
  }) as unknown as any
}
//修改共配账套比例
export const updateShareRate = (data:any) => {
  return axiosHttp({
    url: '/xbfs/fin/company/update/shareRate',
    method: 'post',
    data
  }) as unknown as any
}
/* 公司账户 */
//查询公司账户-list
export const getCompanyAccoutList = (params:object) => {
  return axiosHttp({
    url: '/xbfs/fin/companyAccount/list',
    method: 'get',
    params
  }) as unknown as any
}

//条件查询 * 查询公司账户详情
export const getCompanyAccoutDetail = (params:object) => {
  return axiosHttp({
    url: '/xbfs/fin/companyAccount/detail',
    method: 'get',
    params
  }) as unknown as any
}

//新建公司账户
export const createCompanyAccout = (data:object) => {
  return axiosHttp({
    url: '/xbfs/fin/companyAccount/create',
    method: 'post',
    data
  }) as unknown as any
}

//修改公司账户
export const updateCompanyAccout = (data:object) => {
  return axiosHttp({
    url: '/xbfs/fin/companyAccount/update',
    method: 'post',
    data
  }) as unknown as any
}

//删除公司账户
export const deleteCompanyAccout = (data:any) => {
  return axiosHttp({
    url: `/xbfs/fin/companyAccount/delete?id=${data.id}`,
    method: 'post'
  }) as unknown as any
}
//初始化账户
export const initCompanyAccout = (data:object) => {
  return axiosHttp({
    url: '/xbfs/fin/companyAccount/initialize',
    method: 'post',
    data
  }) as unknown as any
}

/* 快递会计科目 */
//试算平衡
export const axTrialBalance = (params:object) => {
  return axiosHttp({
    url: '/xbfs/fin/accountantSubject/trialBalance',
    method: 'get',
    params
  }) as unknown as any
}
//辅助核算试算平衡
export const axAffixtrialBalance = (params:object) => {
  return axiosHttp({
    url: '/xbfs/fin/accountantSubject/accountingTrialBalance',
    method: 'get',
    params
  }) as unknown as any
}
//查询会计科目列表
export const getAccountantSubjectList = (params:object) => {
  return axiosHttp({
    url: '/xbfs/fin/accountantSubject/list',
    method: 'get',
    params
  }) as unknown as any
}
//查询会计科目列表-非树状
export const axSubjectList = (params:object) => {
  return axiosHttp({
    url: '/xbfs/fin/accountantSubject/detailList',
    method: 'get',
    params
  }) as unknown as any
}
//创建会计科目
export const createAccountantSubject = (data:object) => {
  return axiosHttp({
    url: '/xbfs/fin/accountantSubject/create',
    method: 'post',
    data
  }) as unknown as any
}
//查询会计科目辅助核算组合详情
export const axSubjectBindList = (params:object) => {
  return axiosHttp({
    url: '/xbfs/fin/accountantSubject/getAccountingDetail',
    method: 'get',
    params
  }) as unknown as any
}

//修改会计科目
export const updateAccountantSubject = (data:object) => {
  return axiosHttp({
    url: '/xbfs/fin/accountantSubject/update',
    method: 'post',
    data
  }) as unknown as any
}
export const updateAccountantSubjectPrice = (data:object) => {
  return axiosHttp({
    url: '/xbfs/fin/accountantSubject/update/price',
    method: 'post',
    data
  }) as unknown as any
}
//删除会计科目
export const deleteAccountantSubjectList = (data:any) => {
  return axiosHttp({
    url: `/xbfs/fin/accountantSubject/delete?id=${data.id}`,
    method: 'post'
  }) as unknown as any
}
//会计科目绑定辅助核算
export const axSubjectBindAffix = (data:any) => {
  return axiosHttp({
    url: '/xbfs/fin/accountantSubject/bindAccounting',
    method: 'post',
    data
  }) as unknown as any
}
//会计科目绑定辅助核算详情组合
export const axSubjectBindAffixdetails = (data:any) => {
  return axiosHttp({
    url: '/xbfs/fin/accountantSubject/createAccountingDetail',
    method: 'post',
    data
  }) as unknown as any
}
//会计科目删除辅助核算详情组合
export const axSubjectDelAffixdetails = (data:any) => {
  return axiosHttp({
    url: '/xbfs/fin/accountantSubject/removeAccountingDetail',
    method: 'post',
    data
  }) as unknown as any
}
//会计科目辅助核算详情组合列表
export const axSubjectBinddetailList = (params:any) => {
  return axiosHttp({
    url: '/xbfs/fin/accountantSubject/getAccountingDetail',
    method: 'get',
    params
  }) as unknown as any
}
//会计科目结转
export const axSubjectTrans = (data:object) => {
  return axiosHttp({
    url: '/xbfs/fin/accountProof/carryProof',
    method: 'post',
    data
  }) as unknown as any
}
//会计科目余额
export const accountantSubjectRemainder = (params:object) => {
  return axiosHttp({
    url: '/xbfs/fin/accountantSubject/remainder',
    method: 'get',
    params
  }) as unknown as any
}
//单个会计科目余额
export const singleSubjectRemainder = (params:object) => {
  return axiosHttp({
    url: '/xbfs/fin/accountantSubject/single/remainder',
    method: 'get',
    params
  }) as unknown as any
}
//会计科目余额树状
export const accountantSubjectTree = (params:object) => {
  return axiosHttp({
    // url: '/xbfs/fin/accountantSubject/remainder/v2',
    url: '/xbfs/fin/accountProof/remainder',
    method: 'get',
    params
  }) as unknown as any
}
//辅助核算科目列表
export const axAffixSubjectList = (params:object) => {
  return axiosHttp({
    url: '/xbfs/fin/accountingType/list',
    method: 'get',
    params
  }) as unknown as any
}
//同步客户和供应商
export const axAsycCustAndSupplier = (data:object) => {
  return axiosHttp({
    url: '/xbfs/fin/accountingTypeDetail/sync',
    method: 'post',
    data
  }) as unknown as any
}
//新增辅助核算
export const axCreateAffixSubject = (data:object) => {
  return axiosHttp({
    url: '/xbfs/fin/accountingType/create',
    method: 'post',
    data
  }) as unknown as any
}
//修改辅助核算
export const axUpdateAffixSubject = (data:object) => {
  return axiosHttp({
    url: '/xbfs/fin/accountingType/update',
    method: 'post',
    data
  }) as unknown as any
}
//删除辅助核算
export const axDeleteAffixSubject = (data:any) => {
  return axiosHttp({
    url: `/xbfs/fin/accountingType/delete?id=${data.id}`,
    method: 'post',
    data
  }) as unknown as any
}
//辅助核算详情
export const axAffixSubjectDetail = (params:object) => {
  return axiosHttp({
    url: '/xbfs/fin/accountingTypeDetail/detail',
    method: 'get',
    params
  }) as unknown as any
}
//添加辅助核算详情
export const axAddAffixDetail = (data:any) => {
  return axiosHttp({
    url: '/xbfs/fin/accountingTypeDetail/create',
    method: 'post',
    data
  }) as unknown as any
}
//修改辅助核算详情
export const axUpdateAffixDetail = (data:any) => {
  return axiosHttp({
    url: '/xbfs/fin/accountingTypeDetail/update',
    method: 'post',
    data
  }) as unknown as any
}
//删除辅助核算
export const axDeleteAffixDetail = (data:any) => {
  return axiosHttp({
    url: `/xbfs/fin/accountingTypeDetail/delete?id=${data.id}`,
    method: 'post',
    data
  }) as unknown as any
}
//辅助核算对应会计科目列表
export const axAffixExSubjectList = (params:object) => {
  return axiosHttp({
    url: '/xbfs/fin/accountingTypeSubject/list',
    method: 'get',
    params
  }) as unknown as any
}
//删除辅助核算对应会计科目
export const axDeleteAffixExSubject = (data:any) => {
  return axiosHttp({
    url: `/xbfs/fin/accountingTypeSubject/delete?id=${data.id}`,
    method: 'post',
    data
  }) as unknown as any
}
//添加辅助核算对应会计科目
export const axCreateAffixExSubject = (data:any) => {
  return axiosHttp({
    url: '/xbfs/fin/accountingTypeSubject/createBatch',
    method: 'post',
    data
  }) as unknown as any
}
//添加辅助核算关联记账凭证
export const axCreateAffixProof = (data:any) => {
  return axiosHttp({
    url: '/xbfs/fin/accountingTypeDetailProof/create',
    method: 'post',
    data
  }) as unknown as any
}
//辅助核算和记账凭证关联列表
export const axAffixProofList = (params:object) => {
  return axiosHttp({
    url: '/xbfs/fin/accountingTypeDetailProof/list',
    method: 'get',
    params
  }) as unknown as any
}
//获取科目辅助核算详情-科目余额表
export const axSubjectAffixDetail = (params:any) => {
  return axiosHttp({
    url: '/xbfs/fin/proof/accounting',
    method: 'get',
    params
  }) as unknown as any
}
//辅助核算余额
export const axSubjectAffixBalance = (params:any) => {
  return axiosHttp({
    url: '/xbfs/fin/accountingTypeDetailSubject/list',
    method: 'get',
    params
  }) as unknown as any
}
//单个会计科目余额
export const singleAffixDetailRemainder = (params:object) => {
  return axiosHttp({
    url: '/xbfs/fin/accountantSubject/single/accountingType/remainder',
    method: 'get',
    params
  }) as unknown as any
}
//创建辅助核算余额
export const axCreateAffixBalance = (data:any) => {
  return axiosHttp({
    url: '/xbfs/fin/accountingTypeDetailSubject/create',
    method: 'post',
    data
  }) as unknown as any
}
/* 辅助核算明细账 */
//辅助核算会计科目列表
export const axSubjectAffixList = (params:any) => {
  return axiosHttp({
    url: '/xbfs/fin/accountingTypeSubject/subject',
    method: 'get',
    params
  }) as unknown as any
}
//辅助科目对应会计科目详情
export const axAffixExSubjectDetails = (params:object) => {
  return axiosHttp({
    url: '/xbfs/fin/accountingTypeSubject/detail',
    method: 'get',
    params
  }) as unknown as any
}
/* 费用类型 */
//费用类型绑定业务类型
export const axCtypeBindBtype = (data:any) => {
  return axiosHttp({
    url: '/xbfs/fin/costType/costTypeAddBusiness',
    method: 'post',
    data
  }) as unknown as any
}
//树状查询费用类型
export const getCostTypeTree = (params:object) => {
  return axiosHttp({
    url: '/xbfs/fin/costType/list',
    method: 'get',
    params
  }) as unknown as any
}
//费用类型最大编码
export const axCostTypeCode = (params:object) => {
  return axiosHttp({
    url: '/xbfs/fin/costType/getMaxCostNo',
    method: 'get',
    params
  }) as unknown as any
}
export const getCostTypeTreeV2 = (params:object) => {
  return axiosHttp({
    // url: '/xbfs/fin/costType/list/v2',
    url: '/xbfs/fin/costType/list',
    method: 'get',
    params
  }) as unknown as any
}
//分页查询费用类型
export const getCostTypeList = (params:object) => {
  return axiosHttp({
    url: '/xbfs/fin/costType/list',
    method: 'get',
    params
  }) as unknown as any
}
//创建费用类型
export const createCostType = (data:object) => {
  return axiosHttp({
    url: '/xbfs/fin/costType/create',
    method: 'post',
    data
  }) as unknown as any
}
//修改费用类型
export const updateCostType = (data:object) => {
  return axiosHttp({
    url: '/xbfs/fin/costType/update',
    method: 'post',
    data
  }) as unknown as any
}
//删除费用类型
export const deleteCostType = (data:any) => {
  return axiosHttp({
    url: `/xbfs/fin/costType/delete?id=${data.id}`,
    method: 'post'
  }) as unknown as any
}
//超管删除费用类型
export const deleteCostTypeSuper = (data:any) => {
  return axiosHttp({
    url: `/xbfs/fin/costType/super/delete?id=${data.id}`,
    method: 'post'
  }) as unknown as any
}

/* 所属业务 */
//查询业务数据
export const getBusinessList = (params:object) => {
  return axiosHttp({
    url: '/xbfs/fin/business/list',
    method: 'get',
    params: {orderBy:"sort", orderDir: "asc",...params}
  }) as unknown as any
}
//业务数据--集配税率统计--收入支出
export const axJpInoutList = (params:object) => {
  return axiosHttp({
    url: '/xbfs/fin/financeFdTaxSync/list',
    method: 'get',
    params
  }) as unknown as any
}
//业务数据--集配税率统计--收入支出统计
export const axJpInoutStat = (params:object) => {
  return axiosHttp({
    url: '/xbfs/fin/financeFdTaxSync/totalList',
    method: 'get',
    params
  }) as unknown as any
}
//业务数据--集配税率统计--出库损耗盘点
export const axFinancefdList = (params:object) => {
  return axiosHttp({
    url: '/xbfs/fin/financeFdSync/list',
    method: 'get',
    params
  }) as unknown as any
}

/* 客户资料设置-API接口 */
//获取列表
export const getCustomerList = (params:object) => {
  return axiosHttp({
    url: '/xbfs/fin/customer/detail',
    method: 'get',
    params
  }) as unknown as any
}
//list获取客户-为了排序获取最大num
export const getCustomers = (params:object) => {
  return axiosHttp({
    url: '/xbfs/fin/customer/list',
    method: 'get',
    params
  }) as unknown as any
}
//新增客户
export const createCustomer = (data:object) => {
  return axiosHttp({
    url: '/xbfs/fin/customer/create',
    method: 'post',
    data
  }) as unknown as any
}
//修改客户
export const updateCustomer = (data:object) => {
  return axiosHttp({
    url: '/xbfs/fin/customer/update',
    method: 'post',
    data
  }) as unknown as any
}
//删除客户
export const deleteCustomer = (data:any) => {
  return axiosHttp({
    url: `/xbfs/fin/customer/delete?id=${data.id}`,
    method: 'post',
    data
  }) as unknown as any
}
//详情接口查询
export const getCustomerDetail = (params:object) => {
  return axiosHttp({
    url: '/xbfs/fin/customer/detail',
    method: 'get',
    params
  }) as unknown as any
}

/* 员工账单登记 */
//账单列表
export const getUserBillList = (params:object) => {
  return axiosHttp({
    url: '/xbfs/fin/userBillRegister/list',
    method: 'get',
    params
  }) as unknown as any
}
//修改账单
export const updateUserBill = (data:object) => {
  return axiosHttp({
    url: '/xbfs/fin/userBillRegister/update',
    method: 'post',
    data
  }) as unknown as any
}

//新建报销明细
export const createReinbursement = (data:object) => {
  return axiosHttp({
    url: '/xbfs/fin/userBillRegister/reimbursement/create',
    method: 'post',
    data
  }) as unknown as any
}
//报销明细确认付完款
export const reinbursementPay = (data:object) => {
  return axiosHttp({
    url: '/xbfs/fin/userBillRegister/reimbursement/conformPay',
    method: 'post',
    data
  }) as unknown as any
}
//创建派件代收到付
export const createPjds = (data:object) => {
  return axiosHttp({
    url: '/xbfs/fin/userBillRegister/pjds/create',
    method: 'post',
    data
  }) as unknown as any
}
//派件代收到付确认收款
export const pjdsCollection = (data:object) => {
  return axiosHttp({
    url: '/xbfs/fin/userBillRegister/pjds/conformCollection',
    method: 'post',
    data
  }) as unknown as any
}
//创建发件代收
export const createFjds = (data:object) => {
  return axiosHttp({
    url: '/xbfs/fin/userBillRegister/fjds/create',
    method: 'post',
    data
  }) as unknown as any
}
//发件代收确认返款
export const fjdsPay = (data:object) => {
  return axiosHttp({
    url: '/xbfs/fin/userBillRegister/fjds/conformPay',
    method: 'post',
    data
  }) as unknown as any
}
//创建充值提现
export const createChargeWithdraw = (data:object) => {
  return axiosHttp({
    url: '/xbfs/fin/userBillRegister/chargeWithdraw/create',
    method: 'post',
    data
  }) as unknown as any
}
//充值提现审核
export const reviewChargeWithdraw = (data:any) => {
  return axiosHttp({
    url: '/xbfs/fin/userBillRegister/review',
    method: 'post',
    data
  }) as unknown as any
}
//充值提现确认
export const confirmChargeWithdraw = (data:any) => {
  return axiosHttp({
    url: `/xbfs/fin/userBillRegister/chargeWithdraw/conform?id=${data.id}`,
    method: 'post',
    data
  }) as unknown as any
}
//创建现付发货款
export const createXffhk = (data:object) => {
  return axiosHttp({
    url: '/xbfs/fin/userBillRegister/XfFhk/create',
    method: 'post',
    data
  }) as unknown as any
}
//现付发货确认收款
export const xffhkCollection = (data:object) => {
  return axiosHttp({
    url: '/xbfs/fin/userBillRegister/XfFhk/conformCollection',
    method: 'post',
    data
  }) as unknown as any
}
//创建罚款
export const createFine = (data:object) => {
  return axiosHttp({
    url: '/xbfs/fin/userBillRegister/fine/create',
    method: 'post',
    data
  }) as unknown as any
}
//确认罚款收款
export const fineCollection = (data:object) => {
  return axiosHttp({
    url: '/xbfs/fin/userBillRegister/fine/conformCollection',
    method: 'post',
    data
  }) as unknown as any
}
//新增派费
export const createPf = (data:object) => {
  return axiosHttp({
    url: '/xbfs/fin/userBillRegister/pf/create',
    method: 'post',
    data
  }) as unknown as any
}
//派费确认付款
export const pfPay = (data:object) => {
  return axiosHttp({
    url: '/xbfs/fin/userBillRegister/pf/conformPay',
    method: 'post',
    data
  }) as unknown as any
}
//新建其他费用
export const createOtherCost = (data:object) => {
  return axiosHttp({
    url: '/xbfs/fin/userBillRegister/otherCost/create',
    method: 'post',
    data
  }) as unknown as any
}
//其他费用确认收付款
export const otherConfirm = (data:object) => {
  return axiosHttp({
    url: '/xbfs/fin/userBillRegister/otherCost/conform',
    method: 'post',
    data
  }) as unknown as any
}
//作废操作
export const cancelUserBill = (data:object) => {
  return axiosHttp({
    url: '/xbfs/fin/userBillRegister/cancel',
    method: 'post',
    data
  }) as unknown as any
}
//创建押金借款
export const createYjjk = (data:object) => {
  return axiosHttp({
    // url: '/xbfs/fin/userBillRegister/yjjk/create',
    url: '/xbfs/fin/userBillRegister/lend/create',
    method: 'post',
    data
  }) as unknown as any
}
//押金借款确认实收实付
export const confirmYjjk = (data:object) => {
  return axiosHttp({
    url: '/xbfs/fin/userBillRegister/yjjk/acConform',
    method: 'post',
    data
  }) as unknown as any
}
//押金借款确认返回款
export const confirmYjjkPay = (data:object) => {
  return axiosHttp({
    url: '/xbfs/fin/userBillRegister/yjjk/backConform',
    method: 'post',
    data
  }) as unknown as any
}
//收款记录
export const getUserBillRegisterLog = (params:object) => {
  return axiosHttp({
    url: '/xbfs/fin/userBillRegisterLog/list',
    method: 'get',
    params
  }) as unknown as any
}
//押金借款转结
export const carryYjjk = (data:object) => {
  return axiosHttp({
    url: '/xbfs/fin/userBillRegister/yjjk/carry',
    method: 'post',
    data
  }) as unknown as any
}
//账单统计查询
export const getUserBillStatistic = (params:object) => {
  return axiosHttp({
    url: '/xbfs/fin/userBillRegister/statistics',
    method: 'get',
    params
  }) as unknown as any
}

/* 客户账单 */
export const getCustomerBillList = (params:object) => {
  return axiosHttp({
    url: '/xbfs/fin/customerBill/list',
    method: 'get',
    params
  }) as unknown as any
}
//树状客户账单
export const getCustomerBillTree = (params:object) => {
  return axiosHttp({
    url: '/xbfs/fin/customerBill/tree',
    method: 'get',
    params
  }) as unknown as any
}
//创建现付账单
export const createXfbill = (data:object) => {
  return axiosHttp({
    url: '/xbfs/fin/customerBill/xfBill/create',
    method: 'post',
    data
  }) as unknown as any
}
//现付账单作废
export const cancelXfbill = (data:object) => {
  return axiosHttp({
    url: '/xbfs/fin/customerBill/xfBill/cancel',
    method: 'post',
    data
  }) as unknown as any
}
//收款记录
export const getCustomerBillLog = (params:object) => {
  return axiosHttp({
    url: '/xbfs/fin/customerBillLog/list',
    method: 'get',
    params
  }) as unknown as any
}
//现付账单确认付款
export const xfbillConfirm = (data:object) => {
  return axiosHttp({
    url: '/xbfs/fin/customerBill/xfBill/conform',
    method: 'post',
    data
  }) as unknown as any
}
//现付账单当前公司
export const xfbillCompanyList = (params:object) => {
  return axiosHttp({
    url: '/xbfs/fin/customerBill/xfBill/company',
    method: 'get',
    params
  }) as unknown as any
}
//新建月结账单
export const createYjBill = (data:object) => {
  return axiosHttp({
    url: '/xbfs/fin/customerBill/yjBill/create',
    method: 'post',
    data
  }) as unknown as any
}
//月结账单作废
export const cancelYjBill = (data:object) => {
  return axiosHttp({
    url: '/xbfs/fin/customerBill/yjBill/cancel',
    method: 'post',
    data
  }) as unknown as any
}
//月结账单确认收款
export const yjbillConfirm  = (data:object) => {
  return axiosHttp({
    url: '/xbfs/fin/customerBill/yjBill/conform2',
    method: 'post',
    data
  }) as unknown as any
}
//预付款充值列表
export const getPrePayList = (params:object) => {
  return axiosHttp({
    url: '/xbfs/fin/prePay/list',
    method: 'get',
    params
  }) as unknown as any
}
//新增预付款充值
export const rechargePrepay  = (data:object) => {
  return axiosHttp({
    url: '/xbfs/fin/prePay/recharge',
    method: 'post',
    data
  }) as unknown as any
}
//预付款充值作废
export const cancelPrepay  = (data:object) => {
  return axiosHttp({
    url: '/xbfs/fin/prePay/cancel',
    method: 'post',
    data
  }) as unknown as any
}
//客户回款分析-账单分析数据
export const billAnalyse = (params:object) => {
  return axiosHttp({
    url: '/xbfs/fin/customerBill/billAnalyse',
    method: 'get',
    params
  }) as unknown as any
}
/* 公司收支登记 */
//新建时是否限制公司
export const getCompanylimitStatus = (params:object) => {
  return axiosHttp({
    url: 'xbfs/sys/config/code?code=COMPANYACCOUNTSTATUS',
    method: 'get',
    params
  }) as unknown as any
}
//获取列表
export const getImportTemplate = (params:object) => {
  return axiosHttp({
    url: '/xbfs/sys/config/fileUrl',
    method: 'get',
    params
  }) as unknown as any
}
//获取列表
export const companyBillList = (params:object) => {
  return axiosHttp({
    url: '/xbfs/fin/companyBill/list',
    method: 'get',
    params
  }) as unknown as any
}
//收支登记模板列表
export const dataCompbilltempList = (params:object) => {
  return axiosHttp({
    url: '/xbfs/fin/CompanyBillTemplate/list',
    method: 'get',
    params
  }) as unknown as any
}
//新建收支登记模板
export const dataNewCompbilltemp = (data:object) => {
  return axiosHttp({
    url: '/xbfs/fin/CompanyBillTemplate/create',
    method: 'post',
    data
  }) as unknown as any
}
//删除模板
export const dataDelCompbilltemp = (data:any) => {
  return axiosHttp({
    url: `/xbfs/fin/CompanyBillTemplate/delete?id=${data.id}`,
    method: 'post'
  }) as unknown as any
}
//获取companybillId
export const axGetcompanyBillId = (params:object) => {
  return axiosHttp({
    url: '/xbfs/fin/payLog/getCompanyBill',
    method: 'get',
    params
  }) as unknown as any
}
//平台余额维护-添加
export const createPlantBalance = (data:any) => {
  return axiosHttp({
    url: '/xbfs/fin/payLog/plantBalance/create',
    method: 'post',
    data
  }) as unknown as any
}
//业务查询
export const companyBillBusiness = (params:object) => {
  return axiosHttp({
    url: '/xbfs/fin/companyBill/business/list',
    method: 'get',
    params
  }) as unknown as any
}
//公司收支登记确认
export const confirmCompanyBill = (data:any) => {
  return axiosHttp({
    url: '/xbfs/fin/companyBill/conform/collectionPay',
    method: 'post',
    data
  }) as unknown as any
}
//公司收支登记转账确认
export const confirmCompanyBillTrans = (data:any) => {
  return axiosHttp({
    url: '/xbfs/fin/companyBill/conform/transform',
    method: 'post',
    data
  }) as unknown as any
}
//公司收支登记审核
export const reviewCompanyBill  = (data:any) => {
  return axiosHttp({
    url: '/xbfs/fin/companyBill/review',
    method: 'post',
    data
  }) as unknown as any
}
//创建公司登记
export const createCompanyBill  = (data:object) => {
  return axiosHttp({
    // url: '/xbfs/fin/companyBill/create/collectionPay',
    url:'/xbfs/fin/companyBill/createCompanyBill',
    method: 'post',
    data
  }) as unknown as any
}
//创建公司登记内转
export const createCompanyBillTrans  = (data:object) => {
  return axiosHttp({
    url: '/xbfs/fin/companyBill/create/transform',
    method: 'post',
    data
  }) as unknown as any
}
//删除公司登记
export const deleteCompanyBill  = (data:any) => {
  return axiosHttp({
    url: `/xbfs/fin/companyBill/delete?id=${data.id}`,
    method: 'post',
    data
  }) as unknown as any
}
//更新公司登记
export const updateCompanyBill  = (data:object) => {
  return axiosHttp({
    url: '/xbfs/fin/companyBill/update',
    method: 'post',
    data
  }) as unknown as any
}
//公司登记作废
export const cancelCompanyBill  = (data:object) => {
  return axiosHttp({
    url: '/xbfs/fin/companyBill/cancel',
    method: 'post',
    data
  }) as unknown as any
}
//公司收支登记统计
export const getCompanyBillTotal = (params:object) => {
  return axiosHttp({
    url: '/xbfs/fin/companyBill/total',
    method: 'get',
    params
  }) as unknown as any
}
//审核列表
export const getReviewUserList = (params:object) => {
  return axiosHttp({
    url: '/xbfs/review/reviewUsers/list',
    method: 'get',
    params
  }) as unknown as any
}
//总部数据导入
export const importHeadBill  = (data:object) => {
  return axiosHttp({
    url: '/xbfs/fin/headBill/import',
    method: 'post',
    data
  }) as unknown as any
}
//总部数据原数据
export const getHeadOrigin = (params:object) => {
  return axiosHttp({
    url: '/xbfs/fin/headBillOriginal/list',
    method: 'get',
    params
  }) as unknown as any
}
//总部数据原数据-批量删除收支
export const axHqSzBatchdel = (data:object) => {
  return axiosHttp({
    url: '/xbfs/fin/financeFdTaxSync/deleteBatch',
    method: 'post',
    data
  }) as unknown as any
}
//总部数据原数据-批量删除出库损耗盘点
export const axHqSzotherBatchdel = (data:object) => {
  return axiosHttp({
    url: '/xbfs/fin/financeFdSync/deleteBatch',
    method: 'post',
    data
  }) as unknown as any
}
//集配凭证配置列表
export const axVoucherConfig = (params:object) => {
  return axiosHttp({
    url: '/xbfs/accounting/accountingConfig/getJpFinConfig',
    method: 'get',
    params
  }) as unknown as any
}
//更新集配凭证配置
export const axUpdateVoucherCon = (data:object) => {
  return axiosHttp({
    url: '/xbfs/accounting/accountingConfig/updateJpFinConfig',
    method: 'post',
    data
  }) as unknown as any
}
//结款生成凭证
export const axClosureGenvou = (data:any) => {
  return axiosHttp({
    url: `/xbfs/fin/headBillOriginal/createProof?ym=${data.ym}&companyId=${data.companyId}`,
    method: 'post',
    data
  }) as unknown as any
}
//收入支出生成凭证
export const axInoutGenvou = (data:any) => {
  return axiosHttp({
    url: `/xbfs/fin/financeFdTaxSync/createProof?ym=${data.ym}&companyId=${data.companyId}&type=${data.type}`,
    method: 'post',
    data
  }) as unknown as any
}
//出库损耗盘点生成凭证
export const axFdGenvou = (data:any) => {
  return axiosHttp({
    url: `/xbfs/fin/financeFdSync/createProof?ym=${data.ym}&companyId=${data.companyId}`,
    method: 'post',
    data
  }) as unknown as any
}
//批量生成凭证
export const axOriginProofBactch  = (data:object) => {
  return axiosHttp({
    url: '/xbfs/fin/headBillOriginal/createProof',
    method: 'post',
    data
  }) as unknown as any
}
//总部数据查询
export const getHeadbillList = (params:object) => {
  return axiosHttp({
    url: '/xbfs/fin/headBill/list',
    method: 'get',
    params
  }) as unknown as any
}
//总部数据statics
export const getHeadbillTotal = (params:object) => {
  return axiosHttp({
    url: '/xbfs/fin/headBill/total',
    method: 'get',
    params
  }) as unknown as any
}
//总部数据统计
export const getHeadbillStatic = (params:object) => {
  return axiosHttp({
    url: '/xbfs/fin/headBill/statistics/v2',
    method: 'get',
    params
  }) as unknown as any
}
/* 记账凭证 */
//凭证汇总表列表
export const axProofCollection = (params:object) => {
  return axiosHttp({
    // url: '/xbfs/fin/proof/total',
    url: '/xbfs/fin/accountProof/total',
    method: 'get',
    params
  }) as unknown as any
}
//记账凭证整理
export const balanceProofSubjects  = (data:any) => {
  return axiosHttp({
    url: `/xbfs/fin/proof/proof/balance?ym=${data.ym}&beginNum=${data.beginNum}`,
    method: 'post',
    data
  }) as unknown as any
}
//记账凭证的会计科目
export const getProofSubjects = (params:object) => {
  return axiosHttp({
    url: '/xbfs/fin/companyAccount/getAccountantSubjectId',
    method: 'get',
    params
  }) as unknown as any
}
//分页查询记账凭证模板
export const getProofTemplates = (params:object) => {
  return axiosHttp({
    url: '/xbfs/fin/proofTemplate/list',
    method: 'get',
    params
  }) as unknown as any
}
//修改模板
export const updateProofTemplate = (data:object) => {
  return axiosHttp({
    url: '/xbfs/fin/proofTemplate/update',
    method: 'post',
    data
  }) as unknown as any
}
//删除模板
export const deleteProofTemplate  = (data:any) => {
  return axiosHttp({
    url: `/xbfs/fin/proofTemplate/delete?id=${data.id}`,
    method: 'post'
  }) as unknown as any
}
//创建模板
export const createProofTemplate  = (data:object) => {
  return axiosHttp({
    url: '/xbfs/fin/proofTemplate/create',
    method: 'post',
    data
  }) as unknown as any
}
//记账凭证列号表列表
export const getProofNoList = (params:object) => {
  return axiosHttp({
    url: '/xbfs/fin/proof/list2',
    method: 'get',
    params
  }) as unknown as any
}
//记账凭证月第一条
export const axProofMonthf = (params:object) => {
  return axiosHttp({
    url: '/xbfs/fin/accountProof/monthHead',
    method: 'get',
    params
  }) as unknown as any
}
//记账凭证上一条
export const axProofPre = (params:object) => {
  return axiosHttp({
    url: '/xbfs/fin/accountProof/previous',
    method: 'get',
    params
  }) as unknown as any
}
//记账凭证下一条
export const axProofNext = (params:object) => {
  return axiosHttp({
    url: '/xbfs/fin/accountProof/next',
    method: 'get',
    params
  }) as unknown as any
}
//记账凭证当月最后一条
export const axProofMonthe = (params:object) => {
  return axiosHttp({
    url: '/xbfs/fin/accountProof/monthTail',
    method: 'get',
    params
  }) as unknown as any
}
//记账凭证详情
export const axProofdetail = (params:object) => {
  return axiosHttp({
    url: '/xbfs/fin/accountProof/detail',
    method: 'get',
    params
  }) as unknown as any
}
//会计序时账列表
export const axAccountbook = (params:object) => {
  return axiosHttp({
    url: '/xbfs/fin/accountProof/list',
    method: 'get',
    params
  }) as unknown as any
}
//记账凭证整理
export const axAccountBalance = (data:any) => {
  return axiosHttp({
    url: '/xbfs/fin/accountProof/proof/balance?ym='+data.ym,
    method: 'post',
    data
  }) as unknown as any
}
//通过记账凭证号列表查凭证列表
export const getProofList = (params:object) => {
  return axiosHttp({
    url: '/xbfs/fin/proof/list',
    method: 'get',
    params
  }) as unknown as any
}
//明细账列表
export const getAcountDetailList = (params:object) => {
  return axiosHttp({
    url: '/xbfs/fin/accountProof/detailLedger',
    method: 'get',
    params
  }) as unknown as any
}
//明细账和快递序时账凭证号跳转
export const getProofList2 = (params:object) => {
  return axiosHttp({
    url: '/xbfs/fin/proof/clickProof',
    method: 'get',
    params
  }) as unknown as any
}
//记账凭证审批
export const reviewProof  = (data:object) => {
  return axiosHttp({
    url: '/xbfs/fin/accountProof/review',
    method: 'post',
    data
  }) as unknown as any
}
//记账凭证反审核
export const unReviewProof  = (data:object) => {
  return axiosHttp({
    url: '/xbfs/fin/accountProof/unReview',
    method: 'post',
    data
  }) as unknown as any
}
//删除记账凭证
export const deleteProof = (data:any) => {
  return axiosHttp({
    url: `/xbfs/fin/accountProof/delete?id=${data.id}`,
    method: 'post',
    data
  }) as unknown as any
}
//批量删除记账凭证
export const deleteProofbatch = (data:any) => {
  return axiosHttp({
    url: '/xbfs/fin/accountProof/deleteBatch',
    method: 'post',
    data
  }) as unknown as any
}
//会计结转
export const axProofcarry = (data:any) => {
  return axiosHttp({
    url: '/xbfs/fin/accountProof/carry',
    method: 'post',
    data
  }) as unknown as any
}
//会计反结转
export const axProofuncarry = (data:any) => {
  return axiosHttp({
    url: '/xbfs/fin/accountProof/unCarry',
    method: 'post',
    data
  }) as unknown as any
}
//会计科目记账凭证初期余额
export const getProofInitBalance = (params:object) => {
  return axiosHttp({
    url: '/xbfs/fin/proof/balance',
    method: 'get',
    params
  }) as unknown as any
}
//查找父级科目
export const getParentSubjects = (params:object) => {
  return axiosHttp({
    url: '/xbfs/fin/accountantSubject/getRootSubject'+params,
    method: 'get',
    // params
  }) as unknown as any
}
//新建记账凭证
export const createProof  = (data:any) => {
  return axiosHttp({
    // url: '/xbfs/fin/proof/create/batch',
    url: '/xbfs/fin/accountProof/create',
    method: 'post',
    data
  }) as unknown as any
}
//修改记账凭证
export const axProofUpdate = (data:object) => {
  return axiosHttp({
    // url: '/xbfs/fin/proof/total',
    url: '/xbfs/fin/accountProof/update',
    method: 'post',
    data
  }) as unknown as any
}
//修改记账凭证
export const updateProof  = (data:any) => {
  return axiosHttp({
    url: '/xbfs/fin/proof/update',
    method: 'post',
    data
  }) as unknown as any
}
//修改记账凭证
export const updateProofBatch  = (data:any) => {
  return axiosHttp({
    url: '/xbfs/fin/proof/update/batch',
    method: 'post',
    data
  }) as unknown as any
}
/* 菜单 */
//菜单查询
export const getMenuTree = (params:object) => {
  return axiosHttp({
    url: '/xbfs/sys/menu/tree',
    method: 'get',
    params
  }) as unknown as any
}
//app菜单查询
export const getAppMenuTree = (params:object) => {
  return axiosHttp({
    url: '/xbfs/sys/menu/tree/app',
    method: 'get',
    params
  }) as unknown as any
}
//删除菜单
export const deleteMenu  = (data:any) => {
  return axiosHttp({
    url: `/xbfs/sys/menu/delete?id=${data.id}`,
    method: 'post',
    data
  }) as unknown as any
}
//更新菜单
export const updateMenu  = (data:any) => {
  return axiosHttp({
    url: '/xbfs/sys/menu/update',
    method: 'post',
    data
  }) as unknown as any
}
/* 物料 */
//查询物料表
export const getMaterialList = (params?:object) => {
  return axiosHttp({
    url: '/xbfs/materiel/materiel/list',
    method: 'get',
    params
  }) as unknown as any
}
//删除物料
export const deleteMaterial  = (data:any) => {
  return axiosHttp({
    url: `/xbfs/materiel/materiel/delete?id=${data.id}`,
    method: 'post',
  }) as unknown as any
}
export const axMaterialstock = (params?:object) => {
  return axiosHttp({
    url: '/xbfs/materiel/materiel/stock',
    method: 'get',
    params
  }) as unknown as any
}
//物料一级类目
export const getMaterialLevel1 = (params:object) => {
  return axiosHttp({
    url: '/xbfs/materiel/materielCategory/getLevelOne',
    method: 'get',
    params
  }) as unknown as any
}
//物料二级类目
export const getMaterialLevel2 = (params:object) => {
  return axiosHttp({
    url: '/xbfs/materiel/materielCategory/getLevelTwo',
    method: 'get',
    params
  }) as unknown as any
}
//新建一级类目
export const axCreateCat1 = (data:any) => {
  return axiosHttp({
    url: '/xbfs/materiel/materielCategory/createLevelOne',
    method: 'post',
    data
  }) as unknown as any
}
//新建二级级类目
export const axCreateCat2 = (data:any) => {
  return axiosHttp({
    url: '/xbfs/materiel/materielCategory/createLevelTwo',
    method: 'post',
    data
  }) as unknown as any
}
//修改类目
export const axUpdatecat = (data:any) => {
  return axiosHttp({
    url: '/xbfs/materiel/materielCategory/update',
    method: 'post',
    data
  }) as unknown as any
}
//删除类目
export const axDelcat = (data:any) => {
  return axiosHttp({
    url: '/xbfs/materiel/materielCategory/delete?id='+data.id,
    method: 'post',
    data
  }) as unknown as any
}
//创建新物料
export const createMaterial  = (data:any) => {
  return axiosHttp({
    url: '/xbfs/materiel/materiel/create',
    method: 'post',
    data
  }) as unknown as any
}
//修改物料
export const updateMaterial  = (data:any) => {
  return axiosHttp({
    url: '/xbfs/materiel/materiel/update',
    method: 'post',
    data
  }) as unknown as any
}
//查询物料采购数据
export const getMaterialByList = (params:object) => {
  return axiosHttp({
    url: '/xbfs/materiel/materielBuy/list',
    method: 'get',
    params
  }) as unknown as any
}
//查询所有物料采购数据
export const axAllpurchStat = (params:object) => {
  return axiosHttp({
    url: '/xbfs/materiel/materiel/getAllReport',
    method: 'get',
    params
  }) as unknown as any
}
//查询单个采购数据
export const axSinglepurchStat = (params:object) => {
  return axiosHttp({
    url: '/xbfs/materiel/materiel/getMaterielReport',
    method: 'get',
    params
  }) as unknown as any
}
//创建物料采购
export const createMaterialBuy  = (data:any) => {
  return axiosHttp({
    url: '/xbfs/materiel/materielBuy/create',
    method: 'post',
    data
  }) as unknown as any
}
//删除物料采购
export const deleteMaterialBuy  = (data:any) => {
  return axiosHttp({
    url: `/xbfs/materiel/materielBuy/delete?id=${data.id}`,
    method: 'post'
  }) as unknown as any
}
//修改物料采购
export const updateMaterialBuy  = (data:any) => {
  return axiosHttp({
    url: '/xbfs/materiel/materielBuy/update',
    method: 'post',
    data
  }) as unknown as any
}
//修改物料采购中的公司收支Id
export const updateMaterialBuyCompbill = (data:any) => {
  return axiosHttp({
    url: '/xbfs/materiel/materielBuy/updateCompanyBillId',
    method: 'post',
    data
  }) as unknown as any
}
//物料采购确认付款
export const confirmMaterialBuy  = (data:any) => {
  return axiosHttp({
    url: '/xbfs/materiel/materielBuy/conform/pay',
    method: 'post',
    data
  }) as unknown as any
}
//物料采购确认入库
export const confirmMaterialStock  = (data:any) => {
  return axiosHttp({
    url: '/xbfs/materiel/materielBuy/conform/stock',
    method: 'post',
    data
  }) as unknown as any
}
//物料采购审批
export const reviewMaterialBuy  = (data:any) => {
  return axiosHttp({
    url: '/xbfs/materiel/materielBuy/review',
    method: 'post',
    data
  }) as unknown as any
}
//查询物料申领
export const getMaterialApplyList = (params:object) => {
  return axiosHttp({
    url: '/xbfs/materiel/materielApply/list',
    method: 'get',
    params
  }) as unknown as any
}
//创建物料申领
export const createMaterialApply  = (data:any) => {
  return axiosHttp({
    url: '/xbfs/materiel/materielApply/create',
    method: 'post',
    data
  }) as unknown as any
}
//物料流水记录
export const getMaterialLog = (params:object) => {
  return axiosHttp({
    // url: '/xbfs/materiel/materielApplyLog/list',
    url: '/xbfs/materiel/materielLog/list',
    method: 'get',
    params
  }) as unknown as any
}
//删除物料申领
export const deleteMaterialApply  = (data:any) => {
  return axiosHttp({
    url: `/xbfs/materiel/materielApply/delete?id=${data.id}`,
    method: 'post'
  }) as unknown as any
}
//修改物料申领
export const updateMaterialApply  = (data:any) => {
  return axiosHttp({
    url: '/xbfs/materiel/materielApply/update',
    method: 'post',
    data
  }) as unknown as any
}
//物料申领确认发放
export const confirmMaterialApply  = (data:any) => {
  return axiosHttp({
    url: '/xbfs/materiel/materielApply/conform/apply',
    method: 'post',
    data
  }) as unknown as any
}
//物料申领确认收款
export const confirmMaterialPay  = (data:any) => {
  return axiosHttp({
    url: '/xbfs/materiel/materielApply/conform/pay',
    method: 'post',
    data
  }) as unknown as any
}
//查询物料价格设置
export const getMaterialPriceList = (params:object) => {
  return axiosHttp({
    url: '/xbfs/materiel/materielPrice/list',
    method: 'get',
    params
  }) as unknown as any
}
//删除价格设置
export const deleteMaterialPrice  = (data:any) => {
  return axiosHttp({
    url: `/xbfs/materiel/materielPrice/delete?id=${data.id}`,
    method: 'post'
  }) as unknown as any
}
//新增物料价格
export const createMaterialPrice  = (data:any) => {
  return axiosHttp({
    url: '/xbfs/materiel/materielPrice/create',
    method: 'post',
    data
  }) as unknown as any
}
//修改物料价格
export const updateMaterialPrice  = (data:any) => {
  return axiosHttp({
    url: '/xbfs/materiel/materielPrice/update',
    method: 'post',
    data
  }) as unknown as any
}
//物料当天日志表-每日物料对账表
export const getMaterialDayLog = (params:object) => {
  return axiosHttp({
    // url: '/xbfs/materiel/materielDayLog/list/day',
    url: '/xbfs/materiel/materielLog/list/day',
    method: 'get',
    params
  }) as unknown as any
}
//物料当月日志表-每日物料对账表
export const getMaterialMonthLog = (params:object) => {
  return axiosHttp({
    // url: '/xbfs/materiel/materielDayLog/list/month',
    url: '/xbfs/materiel/materielLog/list/month',
    method: 'get',
    params
  }) as unknown as any
}
//查询物料成本数据
export const getMaterialCostList = (params:object) => {
  return axiosHttp({
    url: '/xbfs/materiel/materielCost/list',
    method: 'get',
    params
  }) as unknown as any
}
//物料成本核算-新增面单成本
export const createMdCost  = (data:any) => {
  return axiosHttp({
    url: '/xbfs/materiel/materielCost/create/md',
    method: 'post',
    data
  }) as unknown as any
}
//物料成本核算-新增非面单成本
export const createFmdCost  = (data:any) => {
  return axiosHttp({
    url: `/xbfs/materiel/materielCost/create/fmd?ym=${data.ym}`,
    method: 'post',
    data
  }) as unknown as any
}


/* 每日报表 */
//交易明细数据-每日流水对账表
export const getPaylogList = (params:object) => {
  return axiosHttp({
    url: '/xbfs/fin/payLog/list',
    method: 'get',
    params
  }) as unknown as any
}
//账户流水校正
export const adjustPayLog = (params:any) => {
  return axiosHttp({
    url: '/xbfs/fin/payLog/balance',
    method: 'get',
    params
  }) as unknown as any
}
//数据统计
export const getPaylogTotal = (params:object) => {
  return axiosHttp({
    url: '/xbfs/fin/payLog/total/list',
    method: 'get',
    params
  }) as unknown as any
}
//修改交易明细-每日流水对账表
export const updatePaylog = (data:any) => {
  return axiosHttp({
    url: '/xbfs/fin/payLog/update',
    method: 'post',
    data
  }) as unknown as any
}
//删除每日流水对账表
export const axPaylogDel = (data:any) => {
  return axiosHttp({
    url: `/xbfs/fin/payLog/delete?id=${data.id}`,
    method: 'post'
  }) as unknown as any
}
//交易明细数据-公司账户对账表
export const companyAccountList = (params:object) => {
  return axiosHttp({
    url: '/xbfs/fin/payLog/companyAccount',
    method: 'get',
    params,
    /* paramsSerializer: (params) => {
      let result = '';
      Object.keys(params).forEach(key => {
      if (key === 'payTimeBegin' || key === 'payTimeEnd') result+=(params[key] + '&')
      else result += `${key}=${encodeURIComponent(params[key])}&`;
      });
      return result.substr(0, result.length - 1);
    } */
  }) as unknown as any
}

/* 财务报表 */
//收派数据录入
export const getSiteDataList = (params:object) => {
  return axiosHttp({
    url: '/xbfs/fin/siteData/list',
    method: 'get',
    params,
  }) as unknown as any
}
//创建或修改收派数据录入
export const updateSiteData = (data:any) => {
  return axiosHttp({
    url: '/xbfs/fin/siteData/createOrUpdate',
    method: 'post',
    data
  }) as unknown as any
}

/* 审批 */
//分页查询审批模板
export const getReviewTempList = (params:object) => {
  return axiosHttp({
    url: '/xbfs/materiel/materielReviewConfig/list',
    method: 'get',
    params,
  }) as unknown as any
}
//修改物料审批模板
export const updateReviewTemp = (data:any) => {
  return axiosHttp({
    url: '/xbfs/materiel/materielReviewConfig/update',
    method: 'post',
    data
  }) as unknown as any
}
//查询审批流程列表
export const getReviewConfigList = (params:object) => {
  return axiosHttp({
    url: '/xbfs/review/reviewConfig/list',
    method: 'get',
    params,
  }) as unknown as any
}
//新建审批流程
export const createReviewConfig = (data:any) => {
  return axiosHttp({
    url: '/xbfs/review/reviewConfig/create',
    method: 'post',
    data
  }) as unknown as any
}
//删除审批流程
export const deleteReviewConfig = (data:any) => {
  return axiosHttp({
    url: `/xbfs/review/reviewConfig/delete?id=${data.id}`,
    method: 'post',
    data
  }) as unknown as any
}
//修改审批流程
export const updateReviewConfig = (data:any) => {
  return axiosHttp({
    url: '/xbfs/review/reviewConfig/update',
    method: 'post',
    data
  }) as unknown as any
}
/* 财务报表 */
//单品牌月度利润表
export const reportProfitList = (params:object) => {
  return axiosHttp({
    // url: '/xbfs/fin/proof/reportProfit/v2',
    // url: '/xbfs/fin/accountProof/reportProfit',
    url:'/xbfs/report/reportProfit',
    method: 'get',
    params,
  }) as unknown as any
}
//共配月度利润表
export const gpProfitList = (params:object) => {
  return axiosHttp({
    // url: '/xbfs/fin/proof/reportProfit/gp/v2',
    url: '/xbfs/fin/accountProof/reportProfit/gp',
    method: 'get',
    params,
  }) as unknown as any
}
//生成报表
export const createProfit = (data:any) => {
  return axiosHttp({
    url: `/xbfs/report/monthProfit/createProfit?year=${data.year}`,
    method: 'post',
    data
  }) as unknown as any
}
//单品牌单票分析表
export const singleAnalyse = (params:object) => {
  return axiosHttp({
    url: '/xbfs/report/monthProfit/analyse',
    method: 'get',
    params,
  }) as unknown as any
}
//单品牌年度分析表
export const singleYearAnalyse = (params:object) => {
  return axiosHttp({
    url: '/xbfs/report/monthProfit/analyse/year',
    method: 'get',
    params,
  }) as unknown as any
}
//共配现金流量表
export const xjllAnalyse = (params:object) => {
  return axiosHttp({
    // url: '/xbfs/report/monthProfit/analyse/cashFlow',
    url: '/xbfs/report/analyse/cashFlow',
    method: 'get',
    params,
  }) as unknown as any
}
//创建现金流和会计科目对应关系
export const axCreateCashflowSubject = (data:any) => {
  return axiosHttp({
    url: '/xbfs/cost/financeCashFlowSubject/create',
    method: 'post',
    data
  }) as unknown as any
}
//删除现金流和会计科目对应关系
export const axDeleteCashflowSubject = (data:any) => {
  return axiosHttp({
    url: `/xbfs/cost/financeCashFlowSubject/delete?id=${data.id}`,
    method: 'post'
  }) as unknown as any
}
//共配资产负债表
export const zcfzAnalyse = (params:object) => {
  return axiosHttp({
    url: '/xbfs/report/monthProfit/analyse/zcfz',
    method: 'get',
    params,
  }) as unknown as any
}
//新共配资产负债表
export const newBalanceSheet = (params:object) => {
  return axiosHttp({
    // url: 'xbfs/report/monthProfit/analyse/balanceSheet',
    url:'xbfs/report/analyse/balanceSheet',
    method: 'get',
    params,
  }) as unknown as any
}
//新增外层subject
export const createBalanceSubject = (data:object) => {
  return axiosHttp({
    url: '/xbfs/fin/balanceSubject/create',
    method: 'post',
    data,
  }) as unknown as any
}
//共配资产负债表-外层subject
export const getBalanceSubject = (params:object) => {
  return axiosHttp({
    url: '/xbfs/fin/balanceSubject/list',
    method: 'get',
    params,
  }) as unknown as any
}
//共配资产负债表-修改外层subject
export const updateBalanceSubject = (data:object) => {
  return axiosHttp({
    url: '/xbfs/fin/balanceSubject/update',
    method: 'post',
    data,
  }) as unknown as any
}
////共配资产负债表-删除外层subject
export const deleteBalanceSubject = (data:any) => {
  return axiosHttp({
    url: `/xbfs/fin/balanceSubject/delete?id=${data.id}`,
    method: 'post',
    data,
  }) as unknown as any
}
//新增里层sheet
export const createBalanceSheet = (data:object) => {
  return axiosHttp({
    url: '/xbfs/fin/balanceSheet/create',
    method: 'post',
    data,
  }) as unknown as any
}
//共配资产负债表-里层sheet
export const getBalanceSheet = (params:object) => {
  return axiosHttp({
    url: '/xbfs/fin/balanceSheet/list',
    method: 'get',
    params,
  }) as unknown as any
}
//共配资产负债表-修改里层sheet
export const updateBalanceSheet = (data:object) => {
  return axiosHttp({
    url: '/xbfs/fin/balanceSheet/update',
    method: 'post',
    data,
  }) as unknown as any
}
//共配资产负债表-删除里层sheet
export const deleteBalanceSheet = (data:any) => {
  return axiosHttp({
    url: `/xbfs/fin/balanceSheet/delete?id=${data.id}`,
    method: 'post',
    data,
  }) as unknown as any
}
//共配资产负债表-新建subject和sheet的关联
export const createSheetSubject = (data:object) => {
  return axiosHttp({
    url: '/xbfs/fin/balanceSheetSubject/create',
    method: 'post',
    data,
  }) as unknown as any
}
//共配资产负债表-subject和sheet
export const getSubjectSheet = (params:object) => {
  return axiosHttp({
    url: '/xbfs/fin/balanceSheetSubject/list',
    method: 'get',
    params,
  }) as unknown as any
}
//共配资产负债表-修改里层subject和sheet的关联
export const updateSheetSubject = (data:object) => {
  return axiosHttp({
    url: '/xbfs/fin/balanceSheetSubject/update',
    method: 'post',
    data,
  }) as unknown as any
}
//共配资产负债表-删除subject和sheet关联
export const deleteSheetSubject = (data:any) => {
  return axiosHttp({
    url: `/xbfs/fin/balanceSheetSubject/delete?id=${data.id}`,
    method: 'post',
    data,
  }) as unknown as any
}
/* 微信小程序图片 */
//小程序图片上传
export const createImage = (data:object) => {
  return axiosHttp({
    url: '/xbfs/sys/images/create',
    method: 'post',
    data,
  }) as unknown as any
}
//小程序图片查询
export const getImageList = (params:object) => {
  return axiosHttp({
    url: '/xbfs/sys/images/list',
    method: 'get',
    params,
  }) as unknown as any
}
/* 文件上传 */
export const fileUpload = (data:object) => {
  return axiosHttp({
    url: '/xbfs/sys/file/upload',
    method: 'post',
    data,
  }) as unknown as any
}
/* 站点 */
export const getSiteInfo = (params:object) => {
  return axiosHttp({
    url: '/xbfs/sys/site/info',
    method: 'get',
    params,
  }) as unknown as any
}
//更新站点信息
export const updateSite = (data:object) => {
  return axiosHttp({
    url: '/xbfs/sys/site/update',
    method: 'post',
    data,
  }) as unknown as any
}
//站点添加银行卡
export const createSiteBank = (data:object) => {
  return axiosHttp({
    url: '/xbfs/pay/financeBank/create',
    method: 'post',
    data,
  }) as unknown as any
}
//站点银行信息
export const getSiteBankList = (params:object) => {
  return axiosHttp({
    url: '/xbfs/pay/financeBank/list',
    method: 'get',
    params,
  }) as unknown as any
}
//查看所有银行信息
export const getBankInfo  = (params:object) => {
  return axiosHttp({
    url: '/xbfs/pay/financeBank/bank',
    method: 'get',
    params
  }) as unknown as any
}
/* 充值提现 */
//微信充值获取二维码链接
export const getWxchargeLink = (data:object) => {
  return axiosHttp({
    url: '/xbfs/pay/wx/wxCharge/native',
    method: 'post',
    data,
  }) as unknown as any
}
export const getAlichargeLink = (data:object) => {
  return axiosHttp({
    url: '/xbfs/pay/ali/aliCharge/native',
    method: 'post',
    data,
  }) as unknown as any
}
//扫描二维码后获取订单信息的接口
export const wxOrderQuery  = (params:object) => {
  return axiosHttp({
    url: '/xbfs/fin/trade/isPay',
    method: 'get',
    params
  }) as unknown as any
}
/* 固定资产 */
//分页查询固定资产
export const getAssets  = (params:object) => {
  return axiosHttp({
    url: '/xbfs/fin/fixedAssets/list',
    method: 'get',
    params
  }) as unknown as any
}
//固定资产类型
export const axAssetTypes  = (params:object) => {
  return axiosHttp({
    url: '/xbfs/fin/fixedAssets/fixedAssetsType/list',
    method: 'get',
    params
  }) as unknown as any
}
//固定资产配置
export const axAssetConfig  = (params:object) => {
  return axiosHttp({
    url: '/xbfs/accounting/accountingConfig/getFixedAssetsConfig',
    method: 'get',
    params
  }) as unknown as any
}
//更新固定资产配置
export const axAssetConfigUpdate  = (data:any) => {
  return axiosHttp({
    url: '/xbfs/accounting/accountingConfig/updateFixedAssetsConfig',
    method: 'post',
    data
  }) as unknown as any
}
//固定资产折旧数据
export const axFixDepre  = (params:any) => {
  return axiosHttp({
    url: '/xbfs/fin/fixedAssetsProof/list',
    method: 'get',
    params
  }) as unknown as any
}
//固定资产折旧统计
export const axDepreStat  = (params:any) => {
  return axiosHttp({
    url: '/xbfs/fin/fixedAssets/listByType',
    method: 'get',
    params
  }) as unknown as any
}
//固定资产一键折旧
export const axBatchdepre = (data:object) => {
  return axiosHttp({
    url: '/xbfs/fin/fixedAssets/depreciation',
    method: 'post',
    data,
  }) as unknown as any
}
//固定资产折旧删除
export const axDeldepre = (data:object) => {
  return axiosHttp({
    url: '/xbfs/fin/fixedAssets/unDepreciation',
    method: 'post',
    data,
  }) as unknown as any
}
//当前折旧月份
export const axDepreDate = (params:any) => {
  return axiosHttp({
    url: '/xbfs/fin/fixedAssets/depreciationYm',
    method: 'get',
    params
  }) as unknown as any
}
//固定资产处置
export const axAssetdisposal = (data:object) => {
  return axiosHttp({
    url: '/xbfs/fin/fixedAssets/disposal',
    method: 'post',
    data,
  }) as unknown as any
}
//创建固定资产
export const createAsset = (data:object) => {
  return axiosHttp({
    url: '/xbfs/fin/fixedAssets/create',
    method: 'post',
    data,
  }) as unknown as any
}
//固定资产借出归还
export const getAssetsInouts = (params:object) => {
  return axiosHttp({
    url: '/xbfs/fin/fixedAssetsUse/list',
    method: 'get',
    params
  }) as unknown as any
}
//固定资产确认付款
export const axAssetPay = (data:object) => {
  return axiosHttp({
    url: '/xbfs/fin/fixedAssets/conform/pay',
    method: 'post',
    data,
  }) as unknown as any
}
//新增固定资产借出归还
export const createAssetInout = (data:object) => {
  return axiosHttp({
    url: '/xbfs/fin/fixedAssetsUse/create',
    method: 'post',
    data,
  }) as unknown as any
}
//查询某个用户资产数量
export const getUserAssetNum = (params:object) => {
  return axiosHttp({
    url: '/xbfs/fin/fixedAssetsUse/userUseFixedAssetNum',
    method: 'get',
    params
  }) as unknown as any
}
//固定资产作废
export const cancelAsset = (data:object) => {
  return axiosHttp({
    url: '/xbfs/fin/fixedAssets/cancel',
    method: 'post',
    data,
  }) as unknown as any
}
//固定资产作废
export const dataDelAsset = (data:object) => {
  return axiosHttp({
    url: '/xbfs/fin/fixedAssets/delete',
    method: 'post',
    data,
  }) as unknown as any
}
//固定资产报废
export const scrapAsset = (data:any) => {
  return axiosHttp({
    url: `/xbfs/fin/fixedAssets/scrap?id=${data.id}`,
    method: 'post'
  }) as unknown as any
}
//生成固定资产折旧凭证
export const createDaProof = (data:object) => {
  return axiosHttp({
    url: '/xbfs/fin/fixedAssetsDaProof/createDaProof',
    method: 'post',
    data,
  }) as unknown as any
}
//查看凭证记录
export const getDaProofList  = (params:object) => {
  return axiosHttp({
    url: '/xbfs/fin/fixedAssetsDaProof/list',
    method: 'get',
    params
  }) as unknown as any
}
/* 超管相关 */
//已有站点列表
export const getSites = (params:object) => {
  return axiosHttp({
    url: '/xbfs/sys/site/super/list',
    method: 'get',
    params
  }) as unknown as any
}
//创建站点
export const createSite = (data:object) => {
  return axiosHttp({
    url: '/xbfs/pay/wx/wxCharge/native',
    method: 'post',
    data,
  }) as unknown as any
}
//修改站点
export const updateSiteSuper = (data:object) => {
  return axiosHttp({
    url: '/xbfs/sys/site/super/update',
    method: 'post',
    data,
  }) as unknown as any
}
//删除站点
export const deleteSite = (data:any) => {
  return axiosHttp({
    url: `/xbfs/sys/site/super/delete?id=${data.id}`,
    method: 'post',
    data,
  }) as unknown as any
}
//申请创建站点
export const applyCreateSite = (data:any) => {
  return axiosHttp({
    url: '/xbfs/sys/siteApply/apply',
    method: 'post',
    data,
  }) as unknown as any
}
//站点申请数据
export const getSiteApplys = (params:object) => {
  return axiosHttp({
    url: '/xbfs/sys/siteApply/list',
    method: 'get',
    params
  }) as unknown as any
}
//修改站点申请
export const updateSiteApply = (data:object) => {
  return axiosHttp({
    url: '/xbfs/sys/siteApply/update',
    method: 'post',
    data
  }) as unknown as any
}
//站点申请审核
export const reviewSiteApply = (data:object) => {
  return axiosHttp({
    url: '/xbfs/sys/siteApply/review',
    method: 'post',
    data
  }) as unknown as any
}
//超管查询会计科目列表
export const getSubjectListSuper = (params:object) => {
  return axiosHttp({
    url: '/xbfs/fin/accountantSubject/super/list',
    method: 'get',
    params
  }) as unknown as any
}
//超管新增会计科目列表
export const createSubjectSuper = (data:any) => {
  return axiosHttp({
    url: '/xbfs/fin/accountantSubject/super/create',
    method: 'post',
    data
  }) as unknown as any
}
//超管删除会计科目列表
export const deleteSubjectSuper = (data:any) => {
  return axiosHttp({
    url: `/xbfs/fin/accountantSubject/super/delete?id=${data.id}`,
    method: 'post',
    data
  }) as unknown as any
}
//超管修改会计科目列表
export const updateSubjectSuper = (data:any) => {
  return axiosHttp({
    url: '/xbfs/fin/accountantSubject/super/update',
    method: 'post',
    data
  }) as unknown as any
}
//超管新增费用类型
export const createCostTypeSuper = (data:any) => {
  return axiosHttp({
    url: '/xbfs/fin/costType/super/create',
    method: 'post',
    data
  }) as unknown as any
}
//超管修改费用类型
export const updateCostTypeSuper = (data:any) => {
  return axiosHttp({
    url: '/xbfs/fin/costType/super/update',
    method: 'post',
    data
  }) as unknown as any
}
//超管费用类型列表
export const getCostTypeListSuper = (params:object) => {
  return axiosHttp({
    url: '/xbfs/fin/costType/super/list',
    method: 'get',
    params
  }) as unknown as any
}
//超管公司列表
export const getCompanyListSuper = (params:object) => {
  return axiosHttp({
    url: '/xbfs/sys/systemCompany/list',
    method: 'get',
    params
  }) as unknown as any
}
//超管查询会计科目列表
export const getSuperAccountantSubjects = (params:object) => {
  return axiosHttp({
    url: '/xbfs/fin/accountantSubject/super/list',
    method: 'get',
    params
  }) as unknown as any
}
//超管查询业务数据
export const getBusinessListSuper = (params:object) => {
  return axiosHttp({
    url: '/xbfs/fin/business/super/list',
    method: 'get',
    params: {orderBy:"sort", orderDir: "desc",...params}
  }) as unknown as any
}
//超管查询站点公司
export const getCompanysSuper = (params:any) => {
  return axiosHttp({
    url: `/xbfs/fin/company/list/site/${params.siteId}`,
    method: 'get',
    params: {filter_NEL_id:params['filter_NEL_id']}
  }) as unknown as any
}
//超管修改站点快递账套
export const updateCompanysSuper = (data:any) => {
  return axiosHttp({
    url: `/xbfs/fin/company/update/site/${data.siteId}`,
    method: 'post',
    data
  }) as unknown as any
}
/* 运费结算 */
//总部数据导入列表
export const getChargeBillOrigin = (params:any) => {
  return axiosHttp({
    url: '/xbfs/cost/fjBillOriginal/list',
    method: 'get',
    params
  }) as unknown as any
}
//价格计算
export const calWeightPrice = (data:any) => {
  return axiosHttp({
    url: '/xbfs/cost/fjBillOriginal/calWeight',
    method: 'post',
    data
  }) as unknown as any
}
//报价组列表
export const getCostQuotList = (params:any) => {
  return axiosHttp({
    url: '/xbfs/cost/costQuote/list',
    method: 'get',
    params
  }) as unknown as any
}
//添加报价组
export const createCostQuot = (data:any) => {
  return axiosHttp({
    url: '/xbfs/cost/costQuote/create',
    method: 'post',
    data
  }) as unknown as any
}
//删除报价组
export const deleteCostQuot = (data:any) => {
  return axiosHttp({
    url: `/xbfs/cost/costQuote/delete?id=${data.id}`,
    method: 'post'
  }) as unknown as any
}
//编辑报价组
export const updateCostQuot = (data:any) => {
  return axiosHttp({
    url: '/xbfs/cost/costQuote/update',
    method: 'post',
    data
  }) as unknown as any
}
//获取报价组详情
export const getCostQuotDetail = (params:any) => {
  return axiosHttp({
    url: '/xbfs/cost/costUserCustomer/list',
    method: 'get',
    params
  }) as unknown as any
}
//删除报价组对象
export const deleteCostQuotUser = (data:any) => {
  return axiosHttp({
    url: `/xbfs/cost/costUserCustomer/delete?id=${data.id}`,
    method: 'post'
  }) as unknown as any
}
//添加报价组对象
export const createCostQuotUser = (data:any) => {
  return axiosHttp({
    url: '/xbfs/cost/costUserCustomer/create',
    method: 'post',
    data
  }) as unknown as any
}
//获取区域组列表
export const getCostQuotAreas = (params:any) => {
  return axiosHttp({
    url: '/xbfs/cost/costQuoteArea/list',
    method: 'get',
    params
  }) as unknown as any
}
//添加区域组
export const createCostQuotArea = (data:any) => {
  return axiosHttp({
    url: '/xbfs/cost/costQuoteArea/create',
    method: 'post',
    data
  }) as unknown as any
}
//修改区域组
export const updateCostQuotArea = (data:any) => {
  return axiosHttp({
    url: '/xbfs/cost/costQuoteArea/update',
    method: 'post',
    data
  }) as unknown as any
}
//删除区域组
export const deleteCostQuotArea = (data:any) => {
  return axiosHttp({
    url: `/xbfs/cost/costQuoteArea/delete?id=${data.id}`,
    method: 'post'
  }) as unknown as any
}
//区域组添加省份
export const addCostQuotProvince = (data:any) => {
  return axiosHttp({
    url: '/xbfs/cost/costQuoteArea/addProvince',
    method: 'post',
    data
  }) as unknown as any
}
//区域组修改省份
export const updateCostQuotProvince = (data:any) => {
  return axiosHttp({
    url: '/xbfs/cost/costQuoteArea/updateProvince',
    method: 'post',
    data
  }) as unknown as any
}
//获取区域组省份列表
export const getSystemProvinces = (params:any) => {
  return axiosHttp({
    url: '/xbfs/sys/systemProvince/list',
    method: 'get',
    params
  }) as unknown as any
}
//查看省份的区域组信息
export const getAreaProvinces = (params:any) => {
  return axiosHttp({
    url: '/xbfs/cost/costQuoteAreaProvince/list',
    method: 'get',
    params
  }) as unknown as any
}
//查看区域组详情
export const getCostQuoteAreaDetail = (params:any) => {
  return axiosHttp({
    url: '/xbfs/cost/costQuoteDetail/list',
    method: 'get',
    params
  }) as unknown as any
}
//添加区域组重量
export const addCostQuotWeight = (data:any) => {
  return axiosHttp({
    url: '/xbfs/cost/costQuoteDetail/create',
    method: 'post',
    data
  }) as unknown as any
}
//情况区域组重量
export const clearCostQuotWeight = (data:any) => {
  return axiosHttp({
    url: `/xbfs/cost/costQuoteArea/removeDetail?id=${data.id}`,
    method: 'post'
  }) as unknown as any
}
//区域组运费计算
export const getExpressCost = (data:any) => {
  return axiosHttp({
    url: '/xbfs/cost/costQuoteDetail/calculatePrice',
    method: 'post',
    data
  }) as unknown as any
}
/* 计费对象关系维护 */
//关系列表
export const getChargeRelationList = (params:any) => {
  return axiosHttp({
    url: '/xbfs/cost/ImportUserCustomer/list',
    method: 'get',
    params
  }) as unknown as any
}
//新增关系
export const addChargeRelation = (data:any) => {
  return axiosHttp({
    url: '/xbfs/cost/ImportUserCustomer/create',
    method: 'post',
    data
  }) as unknown as any
}
//删除关系
export const delChargeRelation = (data:any) => {
  return axiosHttp({
    url: `/xbfs/cost/ImportUserCustomer/delete?id=${data.id}`,
    method: 'post'
  }) as unknown as any
}
//修改关系
export const updateChargeRelation = (data:any) => {
  return axiosHttp({
    url: '/xbfs/cost/ImportUserCustomer/update',
    method: 'post',
    data
  }) as unknown as any
}
/* 月末结账对账 */
//结转
export const monthCarry = (data:any) => {
  return axiosHttp({
    url: '/xbfs/materiel/materielReviewConfig/carry',
    method: 'post',
    data
  }) as unknown as any
}
//反结转
export const antiMonthCarry = (data:any) => {
  return axiosHttp({
    url: '/xbfs/materiel/materielReviewConfig/unCarry',
    method: 'post',
    data
  }) as unknown as any
}
/* 共配现金流量表 */
//现金流公司本期累加变化 /xbfs/fin/cashFlowAdjust/analyse
export const axFlowAdjustsum = (params:any) => {
  return axiosHttp({
    url: '/xbfs/fin/cashFlowAdjust/analyse',
    method: 'get',
    params
  }) as unknown as any
}
//现金流公司全年累加变化 /xbfs/fin/cashFlowAdjust/analyse/ym
export const axYearFlowAdjustsum = (params:any) => {
  return axiosHttp({
    url: '/xbfs/fin/cashFlowAdjust/analyse/ym',
    method: 'get',
    params
  }) as unknown as any
}
//分公司查询流量
export const axCompanyFlow = (params:any) => {
  return axiosHttp({
    url: '/xbfs/fin/cashFlowAdjust/list',
    method: 'get',
    params
  }) as unknown as any
}
//创建流量调整
export const axcreateFlowAdjust= (data:any) => {
  return axiosHttp({
    url: '/xbfs/fin/cashFlowAdjust/create/list',
    method: 'post',
    data
  }) as unknown as any
}
//更新流量调整
export const axupdateFlowAdjust= (data:any) => {
  return axiosHttp({
    url: '/xbfs/fin/cashFlowAdjust/update/list',
    method: 'post',
    data
  }) as unknown as any
}
